import React, { useEffect } from 'react'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { useHistory, Link, useLocation } from 'react-router-dom'

import { LocationState } from '../TwoFactorAuthentication.types'

import styles from './LoginWithTwoFA.module.scss'
import { Button, FormField, Spacer, TextInput, Text, theme } from '@percent/lemonade'
import { useMutation } from '@percent/cause-dashboard/common/hooks'
import { useTranslation } from 'react-i18next'
import { SET_AUTHORISED } from '@percent/cause-dashboard/context/auth'
import { useAuthDispatch } from '@percent/cause-dashboard/common/hooks/useAuthDispatch/useAuthDispatch'
import { useAuthState } from '@percent/cause-dashboard/common/hooks/useAuthState/useAuthState'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { useCausesPortalAnalytics } from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/useCausesDashboardAnalytics'
import { CentralCardLayoutWrapper } from '@percent/cause-dashboard/common/components'

export function LoginWithTwoFA() {
  const { authDispatch } = useAuthDispatch()
  const {
    authState: { isAuthorised }
  } = useAuthState()
  const { authService } = useServices()
  const { identify, track } = useCausesPortalAnalytics()

  const { t } = useTranslation()
  const { push } = useHistory()
  const { state } = useLocation<LocationState>()

  useEffect(() => {
    if (!state?.password) {
      push('/signin')
    }
  }, [state, push])

  useEffect(() => {
    if (isAuthorised) {
      push('/nonprofits')
    }
  }, [isAuthorised, push])

  const [{ errorMessage, isLoading }, { apiFunc: loginUser, setErrorMessage }] = useMutation(
    authService.verifyOtpKey,
    async response => {
      const {
        data: {
          data: { email, id }
        }
      } = response

      await identify({ id, traits: { email } })
      await track('Logged In')

      authDispatch({
        type: SET_AUTHORISED,
        payload: {}
      })
    }
  )

  const formik = useFormik({
    initialValues: {
      token: ''
    },
    validationSchema: () =>
      yup.object().shape({
        token: yup
          .string()
          .required('Required')
          .matches(/^[0-9]+$/, t('errorMessage.onlyDigits'))
          .min(6, t('errorMessage.max6Digit'))
          .max(6, t('errorMessage.max6Digit'))
      }),
    onSubmit: ({ token }: { token: string }) => {
      loginUser({
        token
      })
    }
  })

  const { errors, values, touched, handleChange, handleBlur, handleSubmit } = formik

  useEffect(() => {
    if (values.token) {
      setErrorMessage('')
    }
  }, [values.token, setErrorMessage])

  return (
    <CentralCardLayoutWrapper heading="Two-step verification" handleSubmit={handleSubmit} error={errorMessage}>
      <div className={styles.container}>
        <Text align="left" color={theme.colors.gray700}>
          {t('2fa.enterSixDigitCode')}
        </Text>
        <Spacer size={6} axis="vertical" />
        <FormikProvider value={formik}>
          <FormField
            label={t('form.enterSixDigit')}
            status={touched.token && errors.token ? 'danger' : 'default'}
            statusMessage={errors.token}
            data-testid="token"
          >
            <TextInput
              name="token"
              placeholder={t('form.placeholderSixDigit')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.token}
            />
          </FormField>
        </FormikProvider>
        <Spacer size={6} axis="vertical" />
        <Button stretch size="large" type="submit" data-testid="auth-active-button" loading={isLoading}>
          {t('button.confirm')}
        </Button>
        <Spacer size={6} axis="vertical" />
        <Text size="small" color={theme.colors.gray700}>
          <span>
            <Link
              to={{
                pathname: '/signin/2fa-recovery',
                state: {
                  ...state
                }
              }}
            >
              {t('2fa.useRecoveryCode')}
            </Link>
          </span>
        </Text>
        <Spacer size={2} axis="vertical" />
        <Text size="small" color={theme.colors.gray700}>
          <span>
            <Link to="/signin">{t('2fa.loginDifferentUser')}</Link>
          </span>
        </Text>
      </div>
    </CentralCardLayoutWrapper>
  )
}
