import { Grid, TableCell, TableRow, Typography } from '@material-ui/core'
import { Alert, Badge, Button, IllustratedMessage } from '@percent/lemonade'
import { PressEvent } from '@react-types/shared'
import { useAuthState, useDidMountEffect, useQueryList } from '@percent/cause-dashboard/common/hooks'
import { useTranslation } from 'react-i18next'
import { Loader, Table, ErrorView, DateRangePopper } from '@percent/cause-dashboard/common/components'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { Donation } from '@percent/cause-dashboard/services/donations/donationsService.types'
import { dayJS, getFormattedDate } from '@percent/cause-dashboard/common/utility/date'
import {
  formatAmount,
  formatMinorUnitsValueToFullNumberWithDecimals
} from '@percent/cause-dashboard/common/utility/money/formatAmount'
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import styles from './DonationsPage.module.scss'
import { useCallback, useRef, useState } from 'react'
import { useCSVDownloader } from '@percent/cause-dashboard/common/hooks/useCSVDownloader/useCSVDownloader'
import { csvFileName } from '@percent/cause-dashboard/common/utility/csvFileName'

export const DonationsPage = () => {
  const { t } = useTranslation()
  const {
    donationsService,
    reportingService: { getDonationReport }
  } = useServices()
  const {
    currencyInfo,
    authState: { user }
  } = useAuthState()
  const anchorEl = useRef<HTMLElement | null>(null)
  const [isDatePickerOpened, setIsDatePickerOpened] = useState(false)
  const [queryParams, setQueryParams] = useQueryParams({
    pageSize: withDefault(NumberParam, 25),
    startDate: StringParam,
    endDate: StringParam
  })

  const formatDateParams = <T extends string | null | undefined>(startDate: T, endDate: T) => {
    const isEndDateInTheFuture = endDate ? dayJS(endDate).isAfter(dayJS()) : false

    return {
      startDate: startDate ? dayJS(queryParams.startDate).toISOString() : undefined,
      endDate: endDate && !isEndDateInTheFuture ? dayJS(endDate).toISOString() : dayJS().toISOString()
    } as { startDate: T; endDate: T }
  }

  const [{ data, totalResults, isLoading, errorMessage }, { query, nextPage, previousPage }] = useQueryList(
    donationsService.getDonationsList(user?.organisationId as string),
    {
      ...queryParams,
      ...formatDateParams(queryParams.startDate, queryParams.endDate)
    }
  )

  useDidMountEffect(() => query(queryParams), [queryParams, query])

  const [{ loading: csvDownloaderLoading, errorCSVMessage }, { fetchDonationCSV }] = useCSVDownloader({
    service: getDonationReport(user?.organisationId as string),
    fileName: csvFileName,
    successMessage: t('toast.donationsListExportSuccess')
  })

  const handleClick = (event: PressEvent) => {
    anchorEl.current = event.target as HTMLElement
    setIsDatePickerOpened(!isDatePickerOpened)
  }

  const handleExport = () => {
    if (fetchDonationCSV) {
      fetchDonationCSV(formatDateParams(queryParams.startDate, queryParams.endDate))
    }
  }

  const handleClearDate = useCallback(() => {
    setQueryParams({ startDate: undefined, endDate: undefined })
  }, [setQueryParams])

  const columns = [
    { id: 'typography.donationsTableDonation', isSortable: false, props: { width: '160px' } },
    { id: 'typography.donationsTableDonorName', isSortable: false, props: { width: '160px' } },
    { id: 'typography.donationsTableDonorEmail', isSortable: false },
    { id: 'typography.donationsTableMarketingConsent', isSortable: false, props: { width: '160px' } },
    { id: 'typography.donationsTablePartner', isSortable: false },
    { id: 'typography.donationsTableDonationCreated', isSortable: false, props: { width: '160px' } }
  ]

  const isDateRangeSelected = queryParams.startDate && queryParams.endDate

  if (isLoading) {
    return <Loader />
  }

  if (errorMessage || !!errorCSVMessage) {
    return (
      <div className={styles.errorMessage}>
        <ErrorView errorMessage={errorMessage || (errorCSVMessage as string)} />
      </div>
    )
  }

  const EmptyTable = <IllustratedMessage illustration="no-donations" title={t('typography.noDonations')} />

  const EmptyDateRangeTable = (
    <IllustratedMessage illustration="no-results" title={t('typography.noDonationsInDateRange')} />
  )

  return (
    <Grid container spacing={3} direction="column" wrap="nowrap">
      <Grid item>
        <Table
          data={data}
          isLoading={false}
          totalResults={totalResults}
          previousPage={previousPage}
          nextPage={nextPage}
          columns={columns}
          emptyTableText=""
          emptyTableChildren={isDateRangeSelected ? EmptyDateRangeTable : EmptyTable}
          orderBy=""
          filtersContent={
            (data?.length !== 0 || !!queryParams.startDate) && (
              <>
                <div className={styles.alertWrapper}>
                  <Alert variant="info">{t('typography.donationsInfo')}</Alert>
                </div>
                <div className={styles.titleAndCTAs}>
                  <Typography variant="h6">{t('typography.allDonations')}</Typography>
                  <div className={styles.actionWrapper}>
                    <Button
                      size="small"
                      type="submit"
                      variant={queryParams.startDate ? 'primary' : 'secondary'}
                      data-testid="show-date-range"
                      endIcon="chevron-down"
                      onPress={(e: PressEvent) => handleClick(e)}
                    >
                      {t('typography.date')}{' '}
                      {(isDateRangeSelected &&
                        `${dayJS(queryParams.startDate).format('ll')} - ${dayJS(queryParams.endDate).format('ll')}`) ||
                        t('typography.all')}
                    </Button>
                    {isDateRangeSelected && (
                      <Typography className={styles.clearText} onClick={handleClearDate}>
                        {t('typography.clear')}
                      </Typography>
                    )}
                    <div className={styles.separator} />
                    {csvDownloaderLoading ? (
                      <Button size="small" variant="secondary" data-testid="export-csv-loading">
                        {t('button.exporting')}
                      </Button>
                    ) : (
                      <Button size="small" variant="secondary" data-testid="export-csv-btn" onPress={handleExport}>
                        {t('button.exportCsv')}
                      </Button>
                    )}
                  </div>
                </div>
              </>
            )
          }
        >
          {data?.map(
            (
              {
                amount,
                createdAt,
                currencyCode,
                partner,
                firstName,
                lastName,
                email,
                consentedToBeContactedByOrg
              }: Donation,
              index: number
            ) => (
              <TableRow key={`donation-list-${createdAt}`} className={styles.listItem}>
                <TableCell>
                  {formatAmount({
                    currencyCode,
                    value: formatMinorUnitsValueToFullNumberWithDecimals(
                      amount,
                      currencyInfo?.find(el => el.code === currencyCode)?.minorUnits as number
                    )
                  })}
                </TableCell>
                <TableCell>
                  {firstName ? (
                    <>
                      {firstName} {lastName}
                    </>
                  ) : (
                    <Badge variant="default" icon="view-off">
                      {t('typography.anonymous')}
                    </Badge>
                  )}
                </TableCell>
                <TableCell>
                  {email ? (
                    email
                  ) : (
                    <Badge variant="default" icon="view-off">
                      {t('typography.anonymous')}
                    </Badge>
                  )}
                </TableCell>
                <TableCell>
                  <Badge
                    variant={consentedToBeContactedByOrg === 'yes' ? 'positive' : 'critical'}
                    icon={consentedToBeContactedByOrg === 'yes' ? 'approve' : 'reject'}
                  >
                    {t(`typography.${consentedToBeContactedByOrg === 'yes' ? 'yes' : 'no'}`)}
                  </Badge>
                </TableCell>
                <TableCell>{partner.name}</TableCell>
                <TableCell>{getFormattedDate(createdAt)}</TableCell>
              </TableRow>
            )
          )}
        </Table>
        <DateRangePopper
          open={isDatePickerOpened}
          anchorEl={anchorEl.current}
          placement="bottom-end"
          setOpen={setIsDatePickerOpened}
          setQueryParams={setQueryParams}
          queryParams={queryParams}
        />
      </Grid>
    </Grid>
  )
}
