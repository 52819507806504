import { ProofBankSuccessModalProps } from './ProofBankSuccessModal.types'
import { useTranslation } from 'react-i18next'
import { AcknowledgeModal, Modal } from '@percent/lemonade'

export function ProofBankSuccessModal({
  openProofBankSuccessModal,
  handleCloseProofBankSuccessModal
}: ProofBankSuccessModalProps) {
  const { t } = useTranslation()

  return (
    <Modal open={openProofBankSuccessModal} onClose={handleCloseProofBankSuccessModal}>
      <AcknowledgeModal
        title={t('dialog.proofBankSuccessModal.title')}
        description={t('dialog.proofBankSuccessModal.subTitle')}
        handleClose={handleCloseProofBankSuccessModal}
        result={'positive'}
        buttonText={t('button.close')}
        buttonTestId="modal-add-item-success-button"
      />
    </Modal>
  )
}
