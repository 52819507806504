import { Grid } from '@material-ui/core'
import styles from './DonationFormPage.module.scss'
import { useAuthState } from '@percent/cause-dashboard/common/hooks'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { Code } from '@percent/cause-dashboard/common/components'
import { config } from '@percent/cause-dashboard/config'
import { Spacer } from '@percent/lemonade'

export const DonationFormPage = () => {
  const { t } = useTranslation()
  const {
    authState: { user }
  } = useAuthState()

  // Hello future me: Change this to an ENV variable, and update to real domain when terraform done
  const loaderScript = `<script defer
        src="${config.urls.donationWidget}"></script>`
  const buttonScript = `<div id="pbp-donation-widget"
     data-link-id="${config.ids.donationLink}"
     data-organisation-id="${user?.organisationId}" />`

  return (
    <Grid container spacing={2} className={styles.grid}>
      <Grid container className={styles.container}>
        <Grid item xs={12} className={styles.gridItem}>
          <Typography variant="h5">{t('donationForm.popupHeading')}</Typography>
          <Spacer size="m" axis="horizontal" />
          <Typography className={styles.description}>{t('donationForm.popupDescription')}</Typography>
          <Spacer size="m" axis="horizontal" />
        </Grid>

        <Grid item xs={12} className={styles.gridItem}>
          <Typography variant="h5" className={styles.heading}>
            {t('donationForm.loadLibraryStep.heading')}
          </Typography>
          <Spacer size="m" axis="horizontal" />
          <Typography className={styles.description}>
            {t('donationForm.loadLibraryStep.description.part1')} <br />
            {t('donationForm.loadLibraryStep.description.part2', { head: '<head>' })}
          </Typography>
          <Spacer size="m" axis="horizontal" />
          <Code code={loaderScript} language="html" wordWrap={true} />
          <Spacer size="m" axis="horizontal" />
        </Grid>

        <Grid item xs={12} className={styles.gridItem}>
          <Typography variant="h5" className={styles.heading}>
            {t('donationForm.addButtonStep.heading')}
          </Typography>
          <Spacer size="m" axis="horizontal" />
          <Typography className={styles.description}>
            {t('donationForm.addButtonStep.description.part1')} <br />
            {t('donationForm.addButtonStep.description.part2')}
          </Typography>
          <Spacer size="m" axis="horizontal" />
          <Code code={buttonScript} language="html" wordWrap={true} />
          <Spacer size="m" axis="horizontal" />
        </Grid>
      </Grid>
    </Grid>
  )
}
