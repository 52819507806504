import { CountriesContext } from '@percent/cause-dashboard/context/countries'
import { useContext } from 'react'

export const useCountries = () => {
  const context = useContext(CountriesContext)

  if (context === undefined) {
    throw new Error('useCountries must be used within countriesContextProvider')
  }

  return context
}
