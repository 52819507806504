import { MultiStepForm, OrganisationCard, FormStep } from '@percent/cause-dashboard/common/components'
import { passwordRequirementRegExp } from '@percent/cause-dashboard/common/utility/validation'
import { config } from '@percent/cause-dashboard/config'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import * as Yup from 'yup'
import styles from './SetPasswordToJoinOrganisation.module.scss'
import { FormikValues } from 'formik'
import { SignUpConfirmation } from './SignUpConfirmation'
import { SplitPagesContainer } from '../../layout/splitPagesContainer/SplitPagesContainer'
import { Tooltip } from '@percent/lemonade'

type SetPasswordToJoinOrganisationProps = {
  organisationData: {
    inviterEmail: string
    name: string
    logo?: string
    website?: string
  }
  setPassword: ({ password, consent }: { password: string; consent: boolean }) => void
  errorMessage?: string
  isAccountActive: boolean
  isFormSubmitted: boolean
  setIsFormSubmitted: (isFormSubmitted: boolean) => void
}

export function SetPasswordToJoinOrganisation(props: Readonly<SetPasswordToJoinOrganisationProps>) {
  const { organisationData, setPassword, errorMessage, isAccountActive, isFormSubmitted, setIsFormSubmitted } = props
  const { t } = useTranslation()

  const formSteps = useMemo(() => {
    const passwordStepValidationSchema = Yup.object().shape({
      password: Yup.string()
        .min(8, t('form.minLength', { name: 'Password', length: 8 }))
        .max(64, t('form.maxLength', { name: 'Password', length: 64 }))
        .matches(passwordRequirementRegExp, t('errorMessage.passwordRequirement'))
        .required(t('form.isRequired', { name: 'Password' })),
      agreement: Yup.boolean().oneOf([true], t('errorMessage.acceptTerms')).required(t('errorMessage.acceptTerms'))
    })

    const formSteps: FormStep[] = [
      {
        stepTitle: t('invite.accept.title'),
        stepSubtitle: (
          <>
            <span className={styles.boldText}>{organisationData.inviterEmail}</span>
            {t('invite.accept.subtitle')}
          </>
        ),
        fields: [
          {
            name: 'organisationView',
            type: 'customView',
            customView: (
              <div className={styles.organisationCardWrapper}>
                <OrganisationCard
                  name={organisationData.name}
                  logo={organisationData.logo}
                  website={organisationData.website}
                />
              </div>
            )
          }
        ],
        validationSchema: Yup.object().shape({}),
        buttonTopText: t('invite.accept.buttonTopText'),
        buttonText: t('invite.accept.buttonText')
      },
      {
        stepTitle: t('invite.password.title'),
        stepSubtitle: t('invite.password.subtitle'),
        fields: [
          {
            name: 'password',
            label: t('form.password'),
            placeholder: t('form.enterPassword'),
            type: 'password'
          },
          {
            name: 'agreement',
            checkboxLabel: (
              <span className={styles.checkboxLabel}>
                {t('typography.iAcceptThe')}{' '}
                <a target="_blank" rel="noreferrer" href={config.urls.terms}>
                  {t('typography.termsOfService')}
                </a>{' '}
                {t('typography.and')}{' '}
                <a target="_blank" rel="noreferrer" href={config.urls.privacyAndPolicy}>
                  {t('typography.privacyPolicy')}
                </a>
              </span>
            ),
            type: 'checkbox'
          },
          {
            name: 'marketingConsent',
            checkboxLabel: (
              <span className={`${styles.checkboxLabel} ${styles.marketingConsent}`}>
                {t('typography.marketingConsentStart')}{' '}
                <a href={'#'}>
                  <Tooltip content={t('typography.marketingConsentDetail')}>
                    {t('typography.marketingConsentTooltipTrigger')}
                  </Tooltip>
                </a>{' '}
                {t('typography.marketingConsentEnd')}
              </span>
            ),
            type: 'checkbox'
          }
        ],
        validationSchema: passwordStepValidationSchema,
        buttonText: t('form.signUp')
      }
    ]

    return formSteps
  }, [t, organisationData])

  const onSubmit = async (values: FormikValues) => {
    setPassword({ password: values.password, consent: Boolean(values.marketingConsent) })
    setIsFormSubmitted(true)
  }

  return isFormSubmitted && isAccountActive ? (
    <SignUpConfirmation />
  ) : (
    <SplitPagesContainer>
      <div className={styles.formWrapper} data-testid="set-password-screen">
        <MultiStepForm steps={formSteps} onSubmit={onSubmit} errorMessage={errorMessage} />
      </div>
    </SplitPagesContainer>
  )
}
