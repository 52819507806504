import { CausesPortalLogo } from '@percent/cause-dashboard/common/components'
import { Heading, Spacer, Text } from '@percent/lemonade'
import Plane from '@percent/cause-dashboard/common/assets/images/paper-plane.png'
import { useTranslation } from 'react-i18next'
import styles from './ConfirmationView.module.scss'
import { HELP_LINK } from '@percent/cause-dashboard/constants/support'

export const ConfirmationView = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.confirmationViewContainer}>
      <CausesPortalLogo />
      <Spacer size={8} axis="vertical" />
      <img src={Plane} className={styles.planeImage} alt="paper plane" />
      <Spacer size={8} axis="vertical" />
      <Heading level="h5" align="left">
        {t('form.multiStepAddOrganization.confirmationView.title')}
      </Heading>
      <Spacer size={8} axis="vertical" />
      <Text align="left">{t('form.multiStepAddOrganization.confirmationView.subtitleOne')}</Text>
      <Spacer size={8} axis="vertical" />
      <Text align="left">
        {t('form.multiStepAddOrganization.confirmationView.subtitleTwo')}{' '}
        <span>
          <a href={HELP_LINK} className={styles.helpCenterLink}>
            {t('form.multiStepAddOrganization.confirmationView.helpCenter')}
          </a>
          .
        </span>
      </Text>
      <Spacer size={4} axis="vertical" />
    </div>
  )
}
