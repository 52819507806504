import React from 'react'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { DisableTwoFactorDeviceProps } from '../ChangeTwoFactorDevice/ChangeTwoFactorDevice.types'
import { Button, FormField, Spacer, TextInput, Modal, Text, ButtonText } from '@percent/lemonade'
import { useTranslation } from 'react-i18next'
import styles from './DisableTwoFactorDevice.module.scss'
import { SetupComplete } from '@percent/cause-dashboard/app/auth/login/TwoFactorAuthentication/SetupComplete/SetupComplete'
import { SetupVersion } from '@percent/cause-dashboard/app/auth/login/TwoFactorAuthentication/SetupComplete/SetupComplete.types'
import { CentralCardLayoutWrapper } from '@percent/cause-dashboard/common/components'

export function DisableTwoFactorDevice({
  isOpened,
  onClose,
  errorMessage,
  isLoading,
  disableFunc,
  showCompleted
}: DisableTwoFactorDeviceProps) {
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      password: ''
    },
    validationSchema: () =>
      yup.object().shape({
        password: yup.string().min(8).max(64).required('Required')
      }),
    onSubmit: ({ password }: { password: string }) => {
      disableFunc({ password })
    }
  })

  const { errors, values, touched, dirty, isValid, handleChange, handleBlur, handleSubmit } = formik

  return (
    <Modal open={isOpened} onClose={onClose} isFullscreen whiteBg={false}>
      {showCompleted ? (
        <SetupComplete onFinish={onClose} setupVersion={SetupVersion.Disable2fa} />
      ) : (
        <CentralCardLayoutWrapper heading={t('2fa.disable2fa')} handleSubmit={handleSubmit} error={errorMessage}>
          <div className={styles.formContainer}>
            <Text size="small" align="left">
              {t('2fa.disable2faTitle')}
            </Text>
            <Spacer size={6} axis="vertical" />
            <FormikProvider value={formik}>
              <FormField
                label={t('2fa.passwordLabelDisable')}
                status={touched.password && errors.password ? 'danger' : 'default'}
                statusMessage={errors.password}
                necessity="required"
                description={t('2fa.passwordDisableDescription')}
                data-testid="disable-2fa-password"
              >
                <TextInput
                  type="password"
                  name="password"
                  placeholder={t('2fa.passwordPlaceholder')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />
              </FormField>
            </FormikProvider>
            <Spacer size={6} axis="vertical" />
            <Button disabled={!(isValid && dirty)} type="submit" loading={isLoading} data-testid="disable-2fa-btn">
              {t('button.disable2fa')}
            </Button>
            <Spacer size={6} axis="vertical" />
            <div className={styles.cancelButtonWrapper}>
              <ButtonText onPressEnd={onClose}>{t('button.cancelSetup')}</ButtonText>
            </div>
          </div>
        </CentralCardLayoutWrapper>
      )}
    </Modal>
  )
}
