import { Redirect, RouteProps, Switch, useLocation } from 'react-router-dom'

import { AgentVerifyContainer } from '../agentVerify/AgentVerifyContainer'
import { AccountVerification } from '../auth/accountVerification/AccountVerification'

import { EmailVerificationPage } from '../auth/emailVerification/EmailVerificationPage'
import { ForgotPassword } from '../auth/forgotPassword/ForgotPassword'
import { LoginPage } from '../auth/login/LoginPage'
import { SetPassword } from '../auth/setPassword/SetPassword'
import { ProfilePage } from '../causeProfile/ProfilePage'
import { DonationsPage } from '../donations/DonationsPage'
import { MyAccountContainer } from '../myAccount/MyAccountContainer'
import { PayoutsPage } from '../payouts/PayoutsPage'
import { ProductOffersPage } from '../productOffers/ProductOffersPage'
import { AgentVerificationEmailValidation } from '@percent/cause-dashboard/app/auth/agentVerification/AgentVerificationEmailValidation'
import { ExpiredSessionHandleContainer } from '@percent/cause-dashboard/app/auth/expiredSessionHandleContainer/ExpiredSessionHandleContainer'
import { LoginWithRecoveryCodeContainer } from '@percent/cause-dashboard/app/auth/login/TwoFactorAuthentication/LoginWithRecoveryCode/LoginWithRecoveryCodeContainer'
import { LoginWithTwoFA } from '@percent/cause-dashboard/app/auth/login/TwoFactorAuthentication/LoginWithTwoFA/LoginWithTwoFA'
import { TwoFactorAuthenticationSetup } from '@percent/cause-dashboard/app/auth/login/TwoFactorAuthentication/TwoFactorAuthenticationSetup'
import { MilestonesContainer } from '@percent/cause-dashboard/app/milestones/MilestonesContainer'
import { AuthRoute, UserState } from '@percent/cause-dashboard/common/components/route/AuthRoute'
import { selectAuthState } from '@percent/cause-dashboard/context/auth'
import { useAuthState } from '@percent/cause-dashboard/common/hooks/useAuthState/useAuthState'
import { AgentVerificationDocumentsContainer } from '../agentVerificationDocuments/AgentVerificationDocumentsContainer'
import { AcceptInvitePage } from '../auth/acceptEmailInvitation/AcceptInvitePage'
import { RequestToJoinOrgForm } from '../auth/claim/RequestToJoinOrganisation/RequestToJoinOrganisationForm/RequestToJoinOrgForm'
import { MultiStepAddOrganization } from '../auth/multiStepAddOrganization/MultiStepAddOrganization'
import { MultiStepSignUp } from '../auth/multiStepSignUp/MultiStepSignUp'
import { DonationFormPage } from '../donationForm/DonationFormPage'
import { DashboardLayout } from '@percent/cause-dashboard/app/layout/dashboardLayout/DashboardLayout'
import { PayoutDetailsPage } from '../payouts/PayoutDetailsPage/PayoutDetailsPage'
import { UsersPage } from '../users/UsersPage'
import { ClaimPage } from '../auth/claim/ClaimPage'

export enum RoutePath {
  SIGNIN_2FA_RECOVERY = '/signin/2fa-recovery',
  SIGNIN_2FA = '/signin/2fa',
  SIGNIN_2FA_SETUP = '/signin/2fa-setup',
  SIGNIN = '/signin',
  EXPIRED_SESSION = '/expired-session',
  AGENT_VERIFICATION = '/agent-verification',
  AGENT_VERIFICATION_DOCUMENTS = '/agent-verification-documents',
  SIGNUP = '/signup',
  FORGOT_PASSWORD = '/forgot-password',
  SET_PASSWORD = '/set-password',
  ACCOUNT_VERIFICATION = '/account-verification',
  PRODUCT_OFFERS = '/product-offers',
  PROFILE = '/profile',
  USERS = '/users',
  DONATION_FORM = '/donation-form',
  DONATIONS = '/donations',
  PAYOUTS = '/payouts',
  PAYOUT = '/payouts/:id',
  MY_ACCOUNT = '/my-account',
  MILESTONES = '/milestones',
  CLAIM = '/claim',
  AGENT_VERIFY = '/agent-verify',
  ADD_ORGANIZATION = '/add-organization',
  POST_VERIFICATION_SURVEY = '/post-verification-survey',
  EMAIL_VERIFICATION = '/email-verification',
  ACCEPT_INVITE = '/accept-invite',
  REQUEST_TO_JOIN = '/request-to-join'
}

const OrgIdParamRoute = (props: RouteProps) => {
  const { search } = useLocation()
  const urlQuery = new URLSearchParams(search)
  const orgIdParam = urlQuery.get('organization-id')

  return orgIdParam ? <AuthRoute {...props} allow={[UserState.LoggedOut]} /> : <Redirect to="/" />
}

export function Routes() {
  const { authState } = useAuthState()
  const { claimAccepted } = selectAuthState(authState)

  return (
    <Switch>
      <AuthRoute
        allow={[UserState.LoggedOut]}
        component={LoginWithRecoveryCodeContainer}
        exact
        path={RoutePath.SIGNIN_2FA_RECOVERY}
      />
      <AuthRoute allow={[UserState.LoggedOut]} component={LoginWithTwoFA} exact path={RoutePath.SIGNIN_2FA} />
      <AuthRoute
        allow={[UserState.Any]}
        component={TwoFactorAuthenticationSetup}
        exact
        path={RoutePath.SIGNIN_2FA_SETUP}
      />
      <AuthRoute allow={[UserState.LoggedOut]} component={LoginPage} path={RoutePath.SIGNIN} />
      <AuthRoute
        allow={[UserState.LoggedOut]}
        component={ExpiredSessionHandleContainer}
        path={RoutePath.EXPIRED_SESSION}
      />
      <AuthRoute
        allow={[UserState.Any]}
        component={AgentVerificationEmailValidation}
        path={RoutePath.AGENT_VERIFICATION}
      />
      <AuthRoute
        allow={[UserState.Any]}
        component={AgentVerificationDocumentsContainer}
        path={RoutePath.AGENT_VERIFICATION_DOCUMENTS}
      />
      <OrgIdParamRoute path={RoutePath.SIGNUP} component={MultiStepSignUp} />
      <AuthRoute
        disallow={[UserState.LoggedInClaimMade]}
        allow={[UserState.LoggedOut, UserState.LoggedIn]}
        component={MultiStepAddOrganization}
        path={RoutePath.ADD_ORGANIZATION}
      />
      <AuthRoute allow={[UserState.LoggedOut]} component={ForgotPassword} path={RoutePath.FORGOT_PASSWORD} />
      <AuthRoute allow={[UserState.LoggedOut]} component={SetPassword} path={RoutePath.SET_PASSWORD} />
      <AuthRoute
        allow={[UserState.LoggedOut, UserState.LoggedInUnverified]}
        component={AccountVerification}
        path={RoutePath.ACCOUNT_VERIFICATION}
      />
      <AuthRoute allow={[UserState.LoggedInClaimMade]} path={RoutePath.PRODUCT_OFFERS}>
        <DashboardLayout>
          <ProductOffersPage />
        </DashboardLayout>
      </AuthRoute>
      <AuthRoute allow={[UserState.LoggedInClaimMade]} path={RoutePath.PROFILE}>
        <DashboardLayout>
          <ProfilePage />
        </DashboardLayout>
      </AuthRoute>
      <AuthRoute allow={[UserState.LoggedInClaimMade]} path={RoutePath.USERS}>
        <DashboardLayout>
          <UsersPage />
        </DashboardLayout>
      </AuthRoute>
      <AuthRoute allow={[UserState.LoggedInClaimMade]} path={RoutePath.DONATION_FORM} feature="donationForm">
        <DashboardLayout>
          <DonationFormPage />
        </DashboardLayout>
      </AuthRoute>
      <AuthRoute allow={[UserState.LoggedInClaimMade]} path={RoutePath.DONATIONS}>
        <DashboardLayout>
          <DonationsPage />
        </DashboardLayout>
      </AuthRoute>
      <AuthRoute allow={[UserState.LoggedInClaimMade]} exact path={RoutePath.PAYOUTS}>
        <DashboardLayout>
          <PayoutsPage />
        </DashboardLayout>
      </AuthRoute>
      <AuthRoute allow={[UserState.LoggedInClaimMade]} path={RoutePath.PAYOUT}>
        <DashboardLayout>
          <PayoutDetailsPage />
        </DashboardLayout>
      </AuthRoute>
      <AuthRoute allow={[UserState.LoggedInClaimMade]} path={RoutePath.MY_ACCOUNT}>
        <DashboardLayout>
          <MyAccountContainer />
        </DashboardLayout>
      </AuthRoute>
      {claimAccepted && (
        <AuthRoute allow={[UserState.LoggedInClaimMade]} path={RoutePath.MILESTONES}>
          <DashboardLayout>
            <MilestonesContainer />
          </DashboardLayout>
        </AuthRoute>
      )}
      <AuthRoute
        disallow={[UserState.LoggedInClaimMade]}
        allow={[UserState.LoggedOut, UserState.LoggedIn]}
        component={ClaimPage}
        path={RoutePath.CLAIM}
      />
      <AuthRoute allow={[UserState.Any]} component={AgentVerifyContainer} path={RoutePath.AGENT_VERIFY} />
      <AuthRoute
        allow={[UserState.LoggedInUnverified]}
        component={EmailVerificationPage}
        path={RoutePath.EMAIL_VERIFICATION}
      />
      <AuthRoute allow={[UserState.Any]} component={AcceptInvitePage} path={RoutePath.ACCEPT_INVITE} />
      <AuthRoute allow={[UserState.Any]} component={RequestToJoinOrgForm} path={RoutePath.REQUEST_TO_JOIN} />
      <AuthRoute
        disallow={[UserState.LoggedInClaimMade]}
        allow={[UserState.LoggedOut, UserState.LoggedIn]}
        component={ClaimPage}
        path="/"
      />
      <AuthRoute allow={[UserState.Any]} path="*" />
    </Switch>
  )
}
