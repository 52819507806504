import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router'

import { Loader } from '@percent/cause-dashboard/common/components'

import { Feature } from '@percent/cause-dashboard/app/providers/SplitSdkProvider'
import { RoutePath } from '@percent/cause-dashboard/app/routes/Routes'
import { useAuthState } from '@percent/cause-dashboard/common/hooks'
import { AuthState, selectAuthState } from '@percent/cause-dashboard/context/auth'
import { useLocation } from 'react-router-dom'
import { useFeatureFlag } from '../../hooks/useFeatureFlag/useFeatureFlag'

export enum UserState {
  LoggedOut = 'LoggedOut',
  LoggedInUnverified = 'LoggedInUnverified',
  LoggedIn = 'LoggedIn',
  LoggedInClaimMade = 'LoggedInClaimMade',
  Any = 'Any'
}

const isLoggedInUnverified = ({ loggedIn, userVerified }: AuthState): boolean => loggedIn && !userVerified

const isLoggedInVerified = ({ loggedIn, userVerified, claimMade }: AuthState): boolean =>
  loggedIn && userVerified && !claimMade

const isLoggedInClaimMade = ({ loggedIn, userVerified, claimMade }: AuthState): boolean =>
  loggedIn && userVerified && claimMade

export const mapAuthStateToUserState = (authState: AuthState): UserState => {
  if (isLoggedInUnverified(authState)) return UserState.LoggedInUnverified
  if (isLoggedInVerified(authState)) return UserState.LoggedIn
  if (isLoggedInClaimMade(authState)) return UserState.LoggedInClaimMade
  return UserState.LoggedOut
}

export const getRedirectionRouteForState = ({
  authState,
  urlAfterLogin
}: {
  authState: AuthState
  urlAfterLogin: string
}) => {
  const urlPathWithoutDomain = encodeURIComponent(window.location.pathname)
  const searchParams = new URLSearchParams(window.location.search)

  if (authState.sessionExpired) {
    return `${RoutePath.EXPIRED_SESSION}?next=${urlPathWithoutDomain}${searchParams.toString()}`
  }

  if (isLoggedInUnverified(authState)) {
    return RoutePath.EMAIL_VERIFICATION
  }

  if (isLoggedInClaimMade(authState)) {
    return urlAfterLogin?.startsWith('/') ? urlAfterLogin : RoutePath.PROFILE
  }

  // When organisation is unclaimed, account stays in system and user can login.
  if (isLoggedInVerified(authState)) {
    return RoutePath.CLAIM
  }

  return `${RoutePath.SIGNIN}?next=${urlPathWithoutDomain}${searchParams.toString()}`
}

const canAccessRoute = (authState: AuthState, allowedStates: UserState[], disallowedStatuses: UserState[]) => {
  if (allowedStates.includes(UserState.Any)) return true

  const userState = mapAuthStateToUserState(authState)

  if (disallowedStatuses.some(disallowedStatuses => disallowedStatuses === userState)) {
    return false
  }
  return allowedStates.some(allowedState => allowedState === userState)
}

export type AuthRouteProps = RouteProps & {
  component?: React.ComponentType<any>
  allow: UserState[]
  disallow?: UserState[]
  feature?: Feature
}

export const AuthRoute = ({
  allow,
  disallow,
  feature,
  component: Component,
  children: Children,
  ...rest
}: AuthRouteProps) => {
  const {
    authState: appState,
    authState: { user, isAuthorised }
  } = useAuthState()
  const authState = selectAuthState(appState)
  const featureFlags = useFeatureFlag()
  const location = useLocation()
  const nextUrl = new URLSearchParams(location.search).get('next')
  const redirectRoute = nextUrl ? decodeURIComponent(location.search) : RoutePath.PROFILE

  if (isAuthorised && !user) {
    return <Loader />
  }

  const allowFeature = !feature || featureFlags[feature]

  return (
    <Route
      render={props => {
        if (allowFeature && canAccessRoute(authState, allow, disallow || [])) {
          if (Component) {
            return (<Component {...rest} {...props} />) as any
          }

          return Children
        }

        return <Redirect to={getRedirectionRouteForState({ authState, urlAfterLogin: redirectRoute })} />
      }}
    />
  )
}
