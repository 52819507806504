import { Close } from '@material-ui/icons'
import styles from './SitewideMessage.module.scss'
import { useTranslation } from 'react-i18next'
import { useSitewideMessage } from '@percent/cause-dashboard/common/hooks'

export default function SitewideMessage() {
  const { t } = useTranslation()
  const { shouldShowSitewideMessage, closeSitewideMessage } = useSitewideMessage()

  if (!shouldShowSitewideMessage) {
    return null
  }

  return (
    <div className={styles.container} data-testid="sitewide-message">
      <div className={styles.content}>
        <span className={styles.boldText}>
          {t('typography.sitewideMessage.rebrand1')}{' '}
          <span role="img" className={styles.emoji} aria-label="celebration">
            🎉
          </span>{' '}
          {t('typography.sitewideMessage.rebrand2')}{' '}
          <a href="https://goodstack.org/" target="_blank" rel="noopener noreferrer" className={styles.underline}>
            {t('typography.goodstack')}
          </a>
          .
        </span>
        {t('typography.sitewideMessage.rebrand3')}{' '}
        <a
          href="https://goodstack.org/blog/as-of-today-we-are-excited-to-announce-we-are-rebranding-to-goodstack"
          className={styles.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('typography.sitewideMessage.rebrand4')}{' '}
        </a>
        <span role="img" className={styles.emoji} aria-label="eyes">
          👀
        </span>
      </div>

      <button className={styles.closeButton} onClick={closeSitewideMessage}>
        <Close className={styles.closeIcon} />
      </button>
    </div>
  )
}
