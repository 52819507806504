import { FormikProvider, useFormik } from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup'

import { SET_USER_DETAIL } from '@percent/cause-dashboard/context/auth'
import { FormButtons, Loader, ErrorView } from '@percent/cause-dashboard/common/components'
import { phoneRegExp } from '@percent/cause-dashboard/common/utility/validation'
import { useAuthDispatch, useMutation } from '@percent/cause-dashboard/common/hooks'
import { useTranslation } from 'react-i18next'
import { FormField, Spacer, TextInput, PhoneInput, useToast } from '@percent/lemonade'
import styles from './MyAccountForm.module.scss'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { MyAccountFormProps } from './MyAccountForm.types'

export function MyAccountForm({ name, number, handle, refresh }: MyAccountFormProps) {
  const { accountService } = useServices()
  const { authDispatch } = useAuthDispatch()
  const toast = useToast()
  const { t } = useTranslation()
  const [{ isLoading, errorMessage }, { apiFunc }] = useMutation(accountService.updateAccount, ({ data: { data } }) => {
    authDispatch({
      type: SET_USER_DETAIL,
      payload: {
        user: data
      }
    })
    refresh()
    toast.addToast(t('toast.editUserProfileForm'), 'success')
  })
  const [disableInput, setDisableInput] = useState(true)
  const formik = useFormik({
    initialValues: {
      fullName: name || '',
      preferredName: handle || '',
      phoneNumber: number || ''
    },
    validationSchema: () => {
      if (disableInput) {
        return Yup.object().shape({})
      }

      return Yup.object().shape({
        fullName: Yup.string().max(255, t('errorMessage.shouldNotExceed255')).required(t('errorMessage.required')),
        preferredName: Yup.string().max(255, t('errorMessage.shouldNotExceed255')).required(t('errorMessage.required')),
        phoneNumber: Yup.string()
          .optional()
          .max(25, t('errorMessage.shouldNotExceedNumber'))
          .matches(phoneRegExp, t('errorMessage.notValidPhoneNumber'))
          .min(10, t('errorMessage.tooShort'))
      })
    },
    // eslint-disable-next-line no-unused-vars
    onSubmit: (_userDetails: { fullName?: string; preferredName?: string; phoneNumber?: string }) => {
      if (!dirty) return
      apiFunc({
        fullName: values.fullName,
        preferredName: values.preferredName,
        phoneNumber: values.phoneNumber ? values.phoneNumber : null
      })
    }
  })

  const {
    errors,
    values,
    touched,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
    setFieldValue,
    setFieldTouched
  } = formik

  const handleDisableState = () => {
    setDisableInput(!disableInput)
  }
  const handleCancelForm = () => {
    resetForm()
    setDisableInput(true)
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <form onSubmit={handleSubmit} className={styles.myAccountContainer}>
      <FormikProvider value={formik}>
        <FormField
          label={t('form.fullName')}
          status={touched.fullName && errors.fullName ? 'danger' : 'default'}
          statusMessage={errors.fullName}
          disabled={disableInput}
          data-testid="fullName"
        >
          <TextInput
            name="fullName"
            placeholder=""
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.fullName}
            maxLength={255}
          />
        </FormField>
        <Spacer size={4} axis="vertical" />
        <FormField
          label={t('form.handle')}
          status={touched.preferredName && errors.preferredName ? 'danger' : 'default'}
          statusMessage={errors.preferredName}
          disabled={disableInput}
          data-testid="preferredName"
        >
          <TextInput
            name="preferredName"
            placeholder=""
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.preferredName}
            maxLength={255}
          />
        </FormField>
        <Spacer size={4} axis="vertical" />
        <FormField
          label={t('form.phoneNumber')}
          status={touched.phoneNumber && errors.phoneNumber ? 'danger' : 'default'}
          statusMessage={errors.phoneNumber}
          disabled={disableInput}
          data-testid="phoneNumber"
        >
          <PhoneInput
            name="phoneNumber"
            international
            placeholder=""
            onChange={value => {
              setFieldValue('phoneNumber', value)
              setFieldTouched('phoneNumber', true, true)
            }}
            value={values.phoneNumber}
          />
        </FormField>
      </FormikProvider>
      <Spacer size={4} axis="vertical" />
      {errorMessage && <ErrorView errorMessage={errorMessage} />}
      <FormButtons
        disableInput={disableInput}
        handleSubmit={handleSubmit}
        testId="edit-user-button"
        dirty={dirty}
        handleDisableState={handleDisableState}
        handleCancelForm={handleCancelForm}
      />
    </form>
  )
}
