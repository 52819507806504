import React, { useState } from 'react'
import { FormikProvider, useFormik } from 'formik'

import { validationSchema, ShoppingItem } from '../ModalHelpers'
import styles from '../ItemModal.module.scss'

import { useAuthState, useMutation } from '@percent/cause-dashboard/common/hooks'
import { useTranslation } from 'react-i18next'
import {
  AcknowledgeModal,
  AcknowledgeModalProps,
  ConfirmationModal,
  FormField,
  Modal,
  TextArea
} from '@percent/lemonade'
import { FieldInputCurrency } from '@percent/cause-dashboard/common/components'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { CreateMilestonesItemProps } from '@percent/cause-dashboard/services/milestones/milestonesService.types'
import { floatToIntegerCurrency } from '@percent/cause-dashboard/common/utility/utility'
import { Typography } from '@material-ui/core'
import getSymbolFromCurrency from 'currency-symbol-map'
import { TypeOf } from 'yup'
import { MilestoneModalState } from '../../milestonesTable/MilestonesTable'

export type AddMilestoneModalProps = {
  open: boolean
  refresh: () => void
  onClose: (refresh: boolean) => void
}

export function AddMilestoneModal({ open, onClose, refresh }: Readonly<AddMilestoneModalProps>) {
  const {
    authState: { user, organisation }
  } = useAuthState()
  const { milestonesService } = useServices()
  const [modalState, setModalState] = useState(MilestoneModalState.FORM)
  const [acknowledgeModalData, setAcknowledgeModalData] = useState<AcknowledgeModalProps>({
    title: '',
    description: '',
    result: 'positive'
  })
  const [{ isLoading }, { apiFunc: createMoneyMilestonesItem }] = useMutation(
    milestonesService.createMilestonesItem,
    () => {
      refresh()
      setAcknowledgeModalData({
        title: t('dialog.addMilestone.success'),
        result: 'positive'
      })
      setModalState(MilestoneModalState.ACKNOWLEDGE)
    },
    () => {
      setAcknowledgeModalData({
        title: t('dialog.addMilestone.failure'),
        result: 'negative'
      })
      setModalState(MilestoneModalState.ACKNOWLEDGE)
    }
  )

  const { t } = useTranslation()

  const handleClose = () => {
    resetForm()
    onClose(false)
  }

  const handleCloseAcknowledgeModal = () => {
    setModalState(MilestoneModalState.FORM)
    onClose(false)
  }

  const formik = useFormik({
    initialValues: {
      amount: undefined,
      reward: undefined
    },
    validationSchema,
    onSubmit: submitValues => {
      const { amount: inputAmount, reward } = submitValues as unknown as TypeOf<typeof validationSchema>

      const amount = floatToIntegerCurrency(inputAmount)
      createMoneyMilestonesItem(buildShoppingListItem(user?.organisationId as string, amount, reward))
      resetForm()
    }
  })

  const { errors, values, touched, handleChange, handleBlur, handleSubmit, resetForm, setFieldValue } = formik

  const buildShoppingListItem = (id: string, amount: number, reward: string): CreateMilestonesItemProps => ({
    id,
    amount,
    reward
  })
  const { amount } = values as unknown as ShoppingItem

  return (
    <Modal open={open} onClose={() => onClose(false)}>
      {modalState === MilestoneModalState.FORM && (
        <ConfirmationModal
          title={t('dialog.addMilestone.title')}
          loading={isLoading}
          primaryButtonText={t('button.addMilestone')}
          primaryBtnTestId="save-item-button"
          type="submit"
          secondaryButtonText={t('button.cancel')}
          secondaryBtnTestId="cancel-button"
          handleSubmit={handleSubmit}
          handleClose={handleClose}
        >
          <Typography className={styles.text}>
            {t('dialog.moneyMilestone.helperTextOne')}
            {getSymbolFromCurrency(organisation?.currencyCode || 'USD')}
            {t('dialog.moneyMilestone.helperTextTwo')}
          </Typography>
          <form onSubmit={handleSubmit}>
            <FieldInputCurrency
              name="itemAmount"
              label={t('form.itemAmount')}
              placeHolder={t('form.itemAmountPlaceholder')}
              value={amount}
              onChange={(a: string) => setFieldValue('amount', a)}
              handleBlur={handleBlur}
              error={errors.amount}
              addOn={organisation?.currencyCode}
              isRequiredField
            />
            <FormikProvider value={formik}>
              <FormField
                label={t('form.milestoneReward')}
                status={touched.reward && errors.reward ? 'danger' : 'default'}
                statusMessage={errors.reward}
                data-testid="itemReward"
                necessity="required"
              >
                <TextArea
                  name="reward"
                  maxLength={255}
                  placeholder={t('form.itemRewardPlaceholder')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.reward}
                />
              </FormField>
            </FormikProvider>
          </form>
        </ConfirmationModal>
      )}
      {modalState === MilestoneModalState.ACKNOWLEDGE && (
        <AcknowledgeModal
          title={acknowledgeModalData.title}
          handleClose={handleCloseAcknowledgeModal}
          result={acknowledgeModalData.result as 'positive' | 'negative'}
          buttonText={t('button.done')}
          buttonTestId="add-milestone-acknowledge-modal-button"
          viewTestId="add-milestone-acknowledge-modal-view"
        />
      )}
    </Modal>
  )
}
