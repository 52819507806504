import React, { useEffect, useState } from 'react'
import { Button, Icon, Text, Heading } from '@percent/lemonade'
import styles from './BookCallCard.module.scss'
import { useTranslation } from 'react-i18next'
import { selectAuthState } from '@percent/cause-dashboard/context/auth'
import { useAuthState, useCausesPortalAnalytics } from '@percent/cause-dashboard/common/hooks'
import { CalendlyContainer } from '@percent/cause-dashboard/common/components'

export const BOOK_DEMO_BANNER_CALENDLY_LINK_URL = 'https://calendly.com/d/cqfb-8bv-jdn/meet-your-percent-advisor'

interface BookCallCardProps {
  selectedButtonName?: string
  selectButton: (buttonName?: string) => void
  buttonName: string
}

export function BookCallCard({ selectedButtonName, selectButton, buttonName }: Readonly<BookCallCardProps>) {
  const { t } = useTranslation()

  const acceptedCallCountries = ['USA', 'CAN', 'AUS', 'FRA', 'GBR']
  const [hasClickedCta, setHasClickedCta] = useState(false)

  const { authState } = useAuthState()
  const { claimAccepted } = selectAuthState(authState)
  const { track } = useCausesPortalAnalytics()

  const shouldShowCard = Boolean(
    claimAccepted &&
      authState.organisation &&
      acceptedCallCountries.includes(authState.organisation.countryCode ?? 'NONE')
  )

  let timeoutId: NodeJS.Timeout
  const handleCtaClick = () => {
    setHasClickedCta(true)
    selectButton(buttonName)
    void track('Book Demo Dashboard Banner Clicked', {
      source: 'dashboard',
      organizationId: authState.organisation?.id,
      userId: authState.user?.id
    })

    timeoutId = setTimeout(() => {
      setHasClickedCta(false)
    }, 900)
  }

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [])

  if (!shouldShowCard) {
    return null
  }

  return (
    <>
      <div className={styles.bookCallCard}>
        <div className={styles.contentContainer}>
          <div className={styles.heartWrapper}>
            <Icon name={'heart'} color={'alert400'} />
            <div className={`${styles.heartInner} ${hasClickedCta ? styles.heartBeat : ''}`}>
              <Icon name={'heart'} color={'alert400'} />
            </div>
          </div>
          <div>
            <Heading level="h6">{t('typography.bookCallBannerTitle')}</Heading>
            <Text size="small">{t('typography.bookCallBannerDescription')} </Text>
          </div>

          <div className={styles.rightContent}>
            <Button size={'small'} onPress={handleCtaClick} data-testid={'book-call-banner-cta'}>
              <div className={styles.buttonInnerContainer}>{t('typography.bookCallBannerButtonText')}</div>
            </Button>
          </div>
        </div>
      </div>
      {buttonName === selectedButtonName && (
        <CalendlyContainer
          url={BOOK_DEMO_BANNER_CALENDLY_LINK_URL}
          selectItem={selectButton}
          tracking={{ source: 'Book demo button' }}
        />
      )}
    </>
  )
}
