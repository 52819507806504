import React, { useEffect } from 'react'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { Link, useLocation } from 'react-router-dom'

import { LocationState } from '../TwoFactorAuthentication.types'

import { Button, FormField, Spacer, TextInput, Text, theme } from '@percent/lemonade'
import { useTranslation } from 'react-i18next'
import { FieldError } from '@percent/cause-dashboard/common/components'
import styles from './LoginWithRecoveryCode.module.scss'
import { LoginWithConfirmationCodeProps } from './LoginWithRecoveryCode.types'
import { SaveBackupCode } from './SaveBackupCodeStep/SaveBackupCode'
import { recoveryCodeRegExp } from '@percent/cause-dashboard/common/utility/validation'
import { CentralCardLayoutWrapper } from '@percent/cause-dashboard/common/components'

export const LoginWithRecoveryCode = ({
  errorMessage,
  isLoading,
  loginFunc,
  isRecoveryCodeConfirmed,
  userData,
  setErrorMessage
}: LoginWithConfirmationCodeProps) => {
  const { state } = useLocation<LocationState>()
  const { t } = useTranslation()
  const formik = useFormik({
    initialValues: {
      backupCode: ''
    },
    validationSchema: () =>
      yup.object().shape({
        backupCode: yup
          .string()
          .required('Required')
          .matches(recoveryCodeRegExp, t('errorMessage.invalidRecoveryCodeFormat'))
      }),
    onSubmit: ({ backupCode }: { backupCode: string }) => {
      loginFunc({
        backupCode
      })
    }
  })

  const { errors, values, touched, handleChange, handleBlur, handleSubmit } = formik

  useEffect(() => {
    if (values.backupCode) {
      setErrorMessage('')
    }
  }, [values.backupCode, setErrorMessage])

  return isRecoveryCodeConfirmed ? (
    <SaveBackupCode otpCode={userData?.twoFaBackupCode} userData={userData} />
  ) : (
    <CentralCardLayoutWrapper heading="Two-step verification" handleSubmit={handleSubmit}>
      <div className={styles.container}>
        <Text align="left" color={theme.colors.gray700}>
          {t('form.enterRecoveryCode')}
        </Text>
        <Spacer size={6} axis="vertical" />
        <FormikProvider value={formik}>
          <FormField
            label={t('form.enterRecoveryCodeLabel')}
            status={touched.backupCode && errors.backupCode ? 'danger' : 'default'}
            statusMessage={errors.backupCode}
            data-testid="backupCode"
          >
            <TextInput
              name="backupCode"
              placeholder={t('form.placeholderRecoveryCode')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.backupCode}
            />
          </FormField>
        </FormikProvider>
        {errorMessage && <FieldError error={errorMessage} />}
        <Spacer size={6} axis="vertical" />
        <Button stretch size="large" data-testid="auth-active-button" type="submit" loading={isLoading}>
          {t('button.confirm')}
        </Button>
        <Spacer size={6} axis="vertical" />
        <Text size="small" color={theme.colors.gray700}>
          <span>
            <Link
              to={{
                pathname: '/signin/2fa',
                state: { ...state }
              }}
            >
              {t('2fa.useTwoFACode')}
            </Link>
          </span>
        </Text>
        <Spacer size={2} axis="vertical" />
        <Text size="small" color={theme.colors.gray700}>
          <span>
            <Link to="/signin">{t('2fa.loginDifferentUser')}</Link>
          </span>
        </Text>
      </div>
    </CentralCardLayoutWrapper>
  )
}
