import { Button, Spacer } from '@percent/lemonade'
import { RoutePath } from '../../routes/Routes'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CheckMark from '@percent/cause-dashboard/common/assets/images/email-verified-check-mark.png'
import { CentralCardLayoutWrapper } from '@percent/cause-dashboard/common/components'

export function SignUpConfirmation() {
  const { t } = useTranslation()
  const { push } = useHistory()

  return (
    <CentralCardLayoutWrapper
      heading={t('typography.signupConfirmation.title')}
      CustomHeaderImage={<img src={CheckMark} alt="check mark" />}
    >
      <div data-testid="confirmation-screen">
        <Spacer size={6} axis="vertical" />
        <Button
          stretch
          size="large"
          type="button"
          data-testid="auth-active-button"
          onPress={() => push(RoutePath.SIGNIN)}
        >
          {t('typography.signupConfirmation.btn')}
        </Button>
      </div>
    </CentralCardLayoutWrapper>
  )
}
