import { Loader, CentralCardLayoutWrapper } from '@percent/cause-dashboard/common/components'

import styles from './AgentVerify.module.scss'
import { AgentVerifyProps } from './AgentVerify.types'
import { useEffect } from 'react'
import { AnimatedContainer } from '@percent/lemonade'
import { useTranslation, Trans } from 'react-i18next'
import { ReactComponent as SuccessResultBadge } from '@percent/cause-dashboard/common/assets/images/success-result-badge.svg'
import { AgentVerifyErrorView } from './agentVerifyErrorView/AgentVerifyErrorView'
import { HELP_LINK } from '@percent/cause-dashboard/constants/support'

export const HELP_LINK_TEXT = 'help.goodstack.org'

export function AgentVerify({ isLoading, data, error }: AgentVerifyProps) {
  const { t: loco, i18n } = useTranslation('causeDashboardLoco')

  useEffect(() => {
    if (data?.language) {
      i18n.changeLanguage(data?.language)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  if (error) return <AgentVerifyErrorView error={error} />

  return (
    <CentralCardLayoutWrapper>
      {isLoading && <Loader loaderStyle="authLoader" />}

      {!isLoading && (
        <div className={styles.contentWrapper}>
          <div className={styles.resultBadgeWrapper} data-testid="success-result-badge">
            <AnimatedContainer>
              <SuccessResultBadge />
            </AnimatedContainer>
          </div>
          <h3>
            {loco(
              `webscreen.ask_nonprofit_to_confirm_agent.${
                data?.associationAlreadyRecorded ? 'headingReplySubmitted' : 'headingThanks'
              }`
            )}
          </h3>
          {data?.associationAlreadyRecorded ? (
            <p>
              <Trans
                ns="causeDashboardLoco"
                i18nKey="webscreen.ask_nonprofit_to_confirm_agent.paragraphReplySubmittedDiscount"
                values={{
                  agentFirstName: data?.firstName,
                  agentLastName: data?.lastName,
                  partner: data?.partnerName,
                  reachOutUrl: HELP_LINK_TEXT
                }}
              >
                {/* This is the template string that allows translation to different languages with link */}
                You've already confirmed whether agentFirstName agentLastName is eligible for partner’s program. Use the
                link below if you think there’s been a mistake
                <a href={HELP_LINK} target="_blank" rel="noreferrer">
                  {HELP_LINK_TEXT}
                </a>
              </Trans>
            </p>
          ) : (
            <p>
              {loco('webscreen.ask_nonprofit_to_confirm_agent.paragraphThanksDiscount', {
                agentFirstName: data?.firstName,
                agentLastName: data?.lastName,
                partner: data?.partnerName
              })}
            </p>
          )}
        </div>
      )}
    </CentralCardLayoutWrapper>
  )
}
