import React from 'react'
import { Grid } from '@material-ui/core'
import { Spacer, Text, Heading } from '@percent/lemonade'
import { useTranslation } from 'react-i18next'
import PaperPlane from '@percent/cause-dashboard/common/assets/images/paper-plane.png'
import { Button } from '@percent/lemonade'
import { VERIFICATION_DATA } from '@percent/cause-dashboard/constants/emailVerificationData'
import styles from './EmailVerificationDetail.module.scss'
import { useAuthState } from '@percent/cause-dashboard/common/hooks'
import { HELP_LINK } from '@percent/cause-dashboard/constants/support'

type EmailVerificationDetailProps = {
  handleResendEmailSubmit: () => void
  loading: boolean
  success: boolean
}

export function EmailVerificationDetail({ handleResendEmailSubmit, loading, success }: EmailVerificationDetailProps) {
  const { t } = useTranslation()
  const { authState } = useAuthState()

  return (
    <Grid className={styles.container}>
      <Grid>
        <Grid container>
          <img src={PaperPlane} alt="paper plane" />
        </Grid>
        <Spacer axis="vertical" size={6} />
        <Heading level="h4">{t(VERIFICATION_DATA.title)}</Heading>
        <Spacer axis="vertical" size={6} />
        <Text align="left" size="small">
          {t('emailVerification.verificationData.paragraph1')}
          <b>{authState.user?.email ?? ''}</b>
          {t('emailVerification.verificationData.paragraph2')}
        </Text>
        <Spacer axis="vertical" size={6} />
        <Text align="left" size="small">
          {t('emailVerification.verificationData.paragraph3')}{' '}
          <span>
            <a href={HELP_LINK} className={styles.helpCenterLink}>
              {t('emailVerification.verificationData.paragraph4')}
            </a>
            .
          </span>
        </Text>
        <Spacer axis="vertical" size={8} />
        <Button
          size="large"
          data-testid="resend-email-button"
          disabled={loading || success}
          onPress={handleResendEmailSubmit}
          stretch
        >
          {t(VERIFICATION_DATA.mainButtonTitle)}
        </Button>
      </Grid>
    </Grid>
  )
}
