import React, { useState } from 'react'
import { DeleteMilestoneModalProps } from './DeleteMilestoneModal.types'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { useAuthState, useMutation } from '@percent/cause-dashboard/common/hooks'
import { useTranslation } from 'react-i18next'
import { AcknowledgeModal, AcknowledgeModalProps, ConfirmationModal, Modal } from '@percent/lemonade'
import { MilestoneModalState } from '../../milestonesTable/MilestonesTable'

export function DeleteMilestoneModal({ open, onClose, id, refresh }: Readonly<DeleteMilestoneModalProps>) {
  const {
    authState: { user }
  } = useAuthState()
  const { t } = useTranslation()
  const [modalState, setModalState] = useState(MilestoneModalState.FORM)
  const [acknowledgeModalData, setAcknowledgeModalData] = useState<AcknowledgeModalProps>({
    title: '',
    description: '',
    result: 'positive'
  })
  const { milestonesService } = useServices()
  const [{ isLoading }, { apiFunc: deleteMoneyMilestonesItem }] = useMutation(
    milestonesService.deleteMilestonesItem,
    () => {
      setAcknowledgeModalData({
        title: t('dialog.deleteMilestone.success'),
        result: 'positive'
      })
      setModalState(MilestoneModalState.ACKNOWLEDGE)
    },
    () => {
      setAcknowledgeModalData({
        title: t('dialog.deleteMilestone.failure'),
        result: 'negative'
      })
      setModalState(MilestoneModalState.ACKNOWLEDGE)
    }
  )

  const handleDelete = () => {
    deleteMoneyMilestonesItem({ organisationId: user?.organisationId, id: id.sId })
  }

  const handleCloseAcknowledgeModal = () => {
    if (refresh) {
      refresh()
    }
    onClose()
    setModalState(MilestoneModalState.FORM)
  }

  return (
    <Modal open={open} onClose={onClose}>
      {modalState === MilestoneModalState.FORM && (
        <ConfirmationModal
          title={t('dialog.deleteMilestone.title')}
          loading={isLoading}
          primaryButtonText={t('button.deleteMilestone')}
          primaryBtnTestId="delete-item-button"
          type="submit"
          secondaryButtonText={t('button.cancel')}
          secondaryBtnTestId="edit-cancel-button"
          handleSubmit={handleDelete}
          handleClose={onClose}
        >
          {t('dialog.deleteMilestone.bodyTextOne')} <b>{id.reward}</b>
          {t('dialog.deleteMilestone.bodyTextTwo')}
        </ConfirmationModal>
      )}
      {modalState === MilestoneModalState.ACKNOWLEDGE && (
        <AcknowledgeModal
          title={acknowledgeModalData.title}
          handleClose={handleCloseAcknowledgeModal}
          result={acknowledgeModalData.result as 'positive' | 'negative'}
          buttonText={t('button.done')}
          buttonTestId="delete-milestone-acknowledge-modal-button"
          viewTestId="delete-milestone-acknowledge-modal-view"
        />
      )}
    </Modal>
  )
}
