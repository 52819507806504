import React, { useState } from 'react'
import { Container } from '@material-ui/core'
import { FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import { SET_ORGANISATION_DETAIL } from '@percent/cause-dashboard/context/auth'
import { FormButtons, Loader, ErrorView } from '@percent/cause-dashboard/common/components'
import { useAuthDispatch, useMutation } from '@percent/cause-dashboard/common/hooks'
import { useTranslation } from 'react-i18next'
import { Button, FormField, Spacer, TextArea, TextInput, Tooltip, useToast } from '@percent/lemonade'
import styles from './ProfileForm.module.scss'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { ProfileFormProps } from './ProfileForm.types'
import { ImageUploader } from '../imageUploader/ImageUploader'
import { APIErrorHandler } from '@percent/cause-dashboard/common/library/APIErrorHandler'

export function ProfileForm({
  causeName,
  causeDisplayName,
  causeDescription,
  verifiedCauseOwner,
  causeId,
  refreshApi,
  causeLogo
}: ProfileFormProps) {
  const [currentImage, setCurrentImage] = useState<string | undefined>(causeLogo)
  const [updatedImage, setUpdatedImage] = useState<string | null>(null)
  const { authDispatch } = useAuthDispatch()
  const [disableInput, setDisableInput] = useState(true)
  const { causeService } = useServices()
  const toast = useToast()
  const { t } = useTranslation()
  const [{ isLoading, errorMessage, error }, { apiFunc }] = useMutation(
    causeService.patchOrganisation,
    ({ data: { data } }) => {
      authDispatch({
        type: SET_ORGANISATION_DETAIL,
        payload: {
          organisation: data
        }
      })
      refreshApi()
      toast.addToast(t('toast.editCauseProfileForm'), 'success')
    }
  )

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      displayName: causeDisplayName || '',
      description: causeDescription || '',
      logo: causeLogo
    },
    validationSchema: () => {
      if (disableInput) {
        return Yup.object().shape({})
      }

      return Yup.object().shape({
        displayName: Yup.string().max(255, t('errorMessage.shouldNotExceed255')).required(t('errorMessage.required')),
        description: Yup.string()
          .max(1000, t('errorMessage.shouldNotExceedDescription'))
          .trim()
          .required(t('errorMessage.required')),
        logo: Yup.string().nullable().required(t('errorMessage.required'))
      })
    },
    onSubmit: ({ displayName, description, logo }) => {
      if (!dirty) return
      apiFunc({
        payload: {
          displayName,
          description,
          logo
        },
        organisationId: causeId
      })
    }
  })

  const {
    errors,
    values,
    touched,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
    setFieldValue,
    setFieldError
  } = formik

  const handleDisableState = () => {
    setDisableInput(!disableInput)
  }

  const handleCancelForm = () => {
    resetForm()
    setDisableInput(true)
    setUpdatedImage('')
    setCurrentImage(causeLogo)
  }

  if (isLoading) {
    return <Loader />
  }

  if (errorMessage) {
    return <ErrorView errorMessage={APIErrorHandler(error)} />
  }

  return (
    <form onSubmit={handleSubmit}>
      <Container className={styles.imageContainer}>
        <ImageUploader
          organisationName={causeDisplayName || causeName}
          organisationId={causeId}
          currentImage={currentImage}
          updatedImage={updatedImage}
          setFieldValue={setFieldValue}
          error={errors.logo}
          disableInput={disableInput}
          setUpdatedImage={setUpdatedImage}
          setFieldError={setFieldError}
        />
      </Container>
      <FormikProvider value={formik}>
        <FormField
          label={t('form.causeName')}
          status={touched.displayName && errors.displayName ? 'danger' : 'default'}
          statusMessage={errors.displayName}
          disabled={disableInput}
          data-testid="displayName"
        >
          <TextInput
            name="displayName"
            value={values.displayName}
            placeholder=""
            maxLength={255}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormField>
        <Spacer size={4} axis="vertical" />
        <FormField
          label={t('form.causeDescription')}
          status={touched.description && errors.description ? 'danger' : 'default'}
          statusMessage={errors.description}
          disabled={disableInput}
          data-testid="description"
        >
          <TextArea
            name="description"
            value={values.description}
            placeholder=""
            maxLength={1000}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormField>
      </FormikProvider>
      <Spacer axis="vertical" size={5} />
      {verifiedCauseOwner ? (
        <div className={styles.editDetailsBtnContainer}>
          <FormButtons
            disableInput={disableInput}
            testId="edit-cause-button"
            dirty={dirty}
            handleDisableState={handleDisableState}
            handleCancelForm={handleCancelForm}
            type="submit"
          />
        </div>
      ) : (
        <Tooltip content={t('tooltip.profileFormTip')} placement="top-start">
          <span>
            <Button disabled variant="secondary">
              {t('button.editDetails')}
            </Button>
          </span>
        </Tooltip>
      )}
    </form>
  )
}
