import { Grid } from '@material-ui/core'
import styles from './SplitPagesContainer.module.scss'
import { DETAIL_BULLET_POINTS_DEFAULT, detailTitle } from '@percent/cause-dashboard/constants/emailVerificationData'
import { useTranslation } from 'react-i18next'
import { LocaleKey } from '@percent/cause-dashboard/i18n/config'
import { CausesPortalLogo, Footer, TitleAndBullets } from '@percent/cause-dashboard/common/components'
import { useSitewideMessage } from '@percent/cause-dashboard/common/hooks'

interface SplitPagesContainerProps {
  children: React.ReactNode
  bullets?: { title: LocaleKey }[]
}

export const SplitPagesContainer = ({ children, bullets = DETAIL_BULLET_POINTS_DEFAULT }: SplitPagesContainerProps) => {
  const { t } = useTranslation()

  const { shouldShowSitewideMessage } = useSitewideMessage()
  const shiftDownToAccomodateRebrandNotification = shouldShowSitewideMessage ? 48 : 0
  const minHeightToAccomodateRebrandNotification = shouldShowSitewideMessage ? 'calc(100vh - 48px)' : '100vh'

  return (
    <Grid
      container
      spacing={0}
      className={styles.container}
      style={{
        marginTop: shiftDownToAccomodateRebrandNotification,
        minHeight: minHeightToAccomodateRebrandNotification
      }}
    >
      <Grid item sm={12} md={6} className={styles.leftScreen}>
        <CausesPortalLogo />
        {children}
        <Footer />
      </Grid>
      <Grid item sm={12} md={6} className={styles.titleAndBullets}>
        <TitleAndBullets title={t(detailTitle)} bullets={bullets} />
      </Grid>
    </Grid>
  )
}
