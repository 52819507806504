import { useCallback, useEffect, useState } from 'react'
import styles from './MultiStepForm.module.scss'

import { Grid } from '@material-ui/core'

import { useDefaultPasswordCriteria } from '@percent/cause-dashboard/common/hooks/useDefaultPasswordCriteria/useDefaultPasswordCriteria'
import {
  Button,
  ButtonText,
  Checkbox,
  Feedback,
  FileInput,
  FormField,
  PasswordCriteria,
  PhoneInput,
  Select,
  Spacer,
  Text,
  TextInput
} from '@percent/lemonade'
import { FormikProvider, useFormik } from 'formik'
import { FormField as FormProps, FormValues, MultiStepFormProps } from './MultiStepForm.types'
import { MultiStepFormHeader } from '@percent/cause-dashboard/common/components'

export function MultiStepForm({ steps, onSubmit, errorMessage, isLoading }: MultiStepFormProps) {
  const [currentStep, setCurrentStep] = useState(0)
  const defaultPasswordCriteria = useDefaultPasswordCriteria()

  const formik = useFormik<FormValues>({
    initialValues: steps.reduce(
      (values, step) => ({
        ...values,
        ...step.fields.reduce(
          (fieldValues, field) => ({
            ...fieldValues,
            [field.name]: field.type === 'number' ? undefined : field.defaultValue || ''
          }),
          {}
        )
      }),
      {}
    ),
    validationSchema: steps[currentStep].validationSchema,
    validateOnMount: true,
    initialErrors: { _initial: 'form not yet validated' },
    onSubmit: async values => {
      const isLastStep = currentStep === steps.length - 1
      if (isLastStep) {
        await onSubmit(values)
      } else {
        const currentStepAction = steps[currentStep].stepAction
        if (currentStepAction) {
          await currentStepAction()
        }
        setCurrentStep(currentStep + 1)
      }
    }
  })

  const {
    errors,
    values,
    touched,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    validateForm,
    setTouched,
    setFieldValue,
    setFieldTouched
  } = formik

  useEffect(() => {
    validateForm()
    setTouched({})
  }, [currentStep, validateForm, setTouched])

  const getSelect = (field: FormProps) => {
    return (
      <Select
        placeholder={field.placeholder}
        options={field.options || []}
        defaultValue={field.options?.find(
          ({ value, label }) =>
            value === values[field.name] && {
              label,
              value
            }
        )}
        onChange={event => {
          steps[currentStep]?.selectValue?.(event.value)
          setFieldValue(field.name, event.value)
          setTouched({ ...touched, [field.name]: true })
        }}
        disabled={isLoading}
      />
    )
  }

  const getTextField = (field: FormProps) => {
    return (
      <TextInput
        type={field.type === 'password' ? 'password' : field.type === 'number' ? 'number' : 'text'}
        name={field.name}
        placeholder={field.placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values[field.name]}
        data-testid={field.dataTestId}
        disabled={isLoading}
      />
    )
  }

  const getFileField = (field: FormProps) => {
    return (
      <FileInput
        dataTestId={field.dataTestId}
        placeholder={field.placeholder}
        fileUploadLabel={field.fileUploadLabel}
        file={values[field.name] as any}
        onChange={file => {
          setTouched({ ...touched, [field.name]: true })
          setFieldValue(field.name, file)
        }}
      />
    )
  }

  const getPhoneInput = (field: FormProps) => {
    return (
      <PhoneInput
        international
        placeholder={field.placeholder}
        value={values[field.name] as string | undefined}
        onChange={value => {
          setFieldValue(field.name, value)
          setFieldTouched(field.name, true, true)
        }}
        defaultCountry={field.defaultCountry ?? undefined}
        disabled={isLoading}
      />
    )
  }

  const getCheckboxField = useCallback(
    (field: FormProps) => {
      return (
        <Checkbox
          name={field.name}
          value={values[field.name]?.toString()}
          onBlur={handleBlur}
          variant="default"
          onChange={() => {
            setTouched({ ...touched, [field.name]: true })
            setFieldValue(field.name, !values[field.name])
          }}
          active={!!values[field.name]}
          label={field.checkboxLabel}
          disabled={isLoading}
        />
      )
    },
    [handleBlur, setFieldValue, setTouched, touched, values, isLoading]
  )

  const getFieldType = (field: FormProps) => {
    switch (field.type) {
      case 'select':
        return getSelect(field)
      case 'file':
        return getFileField(field)
      case 'checkbox':
        return getCheckboxField(field)
      case 'customView':
        return field.customView ?? <></>
      case 'phone':
        return getPhoneInput(field)
      default:
        return getTextField(field)
    }
  }

  const shouldBtnBeDisabledByDefault = () => {
    return Boolean(steps[currentStep].skipButtonText) && !Object.values(touched).some(Boolean)
  }

  return (
    <Grid container className={styles.formContainer}>
      <form onSubmit={handleSubmit} className={styles.form}>
        <MultiStepFormHeader title={steps[currentStep].stepTitle} subtitle={steps[currentStep].stepSubtitle} />
        <Spacer size={2} axis="vertical" />
        <FormikProvider value={formik}>
          {steps[currentStep].fields.map(field => {
            return (
              <div key={field.name}>
                <Spacer size={4} axis="vertical" />
                <FormField
                  label={field.label}
                  status={touched[field.name] && errors[field.name] ? 'danger' : 'default'}
                  statusMessage={errors[field.name]}
                  description={field.description}
                  necessity={field.optional ?? false ? undefined : 'required'}
                >
                  {getFieldType(field)}
                </FormField>
                {field.type === 'password' && (
                  <>
                    <Spacer size={6} axis="vertical" />
                    <PasswordCriteria
                      password={values[field.name] as string}
                      visible={(values[field.name] as string).length > 0}
                      passwordCriteria={defaultPasswordCriteria}
                    />
                  </>
                )}
              </div>
            )
          })}
        </FormikProvider>
        {errorMessage && <Feedback variant="critical">{errorMessage}</Feedback>}
        <Spacer size={6} axis="vertical" />
        {steps[currentStep].buttonTopText && <Text color="#404858">{steps[currentStep].buttonTopText}</Text>}
        <Spacer size={6} axis="vertical" />
        <Button
          stretch
          size="large"
          data-testid={steps[currentStep].dataTestId}
          disabled={isSubmitting || !isValid || shouldBtnBeDisabledByDefault()}
          type="submit"
          loading={isLoading}
        >
          {steps[currentStep].buttonText}
        </Button>
        {steps[currentStep].buttonBottomText && (
          <>
            <Spacer size={2} axis="vertical" />
            <Text size="small">{steps[currentStep].buttonBottomText}</Text>
          </>
        )}
        {steps[currentStep].skipButtonText && (
          <div className={styles.skipButtonContainer}>
            <Spacer size={6} axis="vertical" />
            <ButtonText
              onPressEnd={async () => {
                const skipAction = steps[currentStep].skipButtonAction
                if (skipAction) {
                  await skipAction()
                }
                setCurrentStep(currentStep + 1)
              }}
              data-testid="skip-step-btn"
            >
              {steps[currentStep].skipButtonText}
            </ButtonText>
          </div>
        )}
      </form>
    </Grid>
  )
}
