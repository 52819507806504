import React from 'react'
import { useLocation, Link } from 'react-router-dom'
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import classNames from 'classnames/bind'
import styles from './CustomMenuItem.module.scss'
import { ComingSoonTag } from '@percent/cause-dashboard/common/components'

type CustomMenuItemProps = {
  Logo: React.FC<React.SVGProps<SVGSVGElement>>
  name: string
  to?: string
  soon?: boolean
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
}

export function CustomMenuItem({ Logo, name, to = '', soon, onClick }: CustomMenuItemProps) {
  const location = useLocation()
  const isActive = location.pathname.match(to)
  const cx = classNames.bind(styles)

  return (
    <Link to={to} onClick={onClick}>
      <ListItem
        button
        className={cx({
          listItem: true,
          active: isActive,
          default: soon,
          clickable: !isActive && !soon
        })}
      >
        <ListItemIcon>
          <Logo
            width="20px"
            height="20px"
            className={isActive ? styles.svgActive : soon ? styles.svgDefault : styles.svgClickable}
          />
        </ListItemIcon>
        <ListItemText primary={name} />
        {soon && <ComingSoonTag />}
      </ListItem>
    </Link>
  )
}

CustomMenuItem.defaultProps = {
  to: '',
  soon: false,
  // eslint-disable-next-line
  onClick: () => {}
}
