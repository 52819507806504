import { ProductOffer, ProductOfferType } from '@percent/cause-dashboard/app/productOffers/data/data.types'
import { PERCENT_SERVICE_CALENDLY_URL_TILES } from './appStoreUrls'
import { ProgramName } from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/marketplaceAnalytics.types'

export const percentServicesCatalog = {
  googleWorkspaceSupport: {
    type: ProductOfferType.PercentService,
    name: 'Google Workspace support',
    tracking: ProgramName.GoogleWorkspace,
    description: 'Get help with set up and ongoing management of Workspace',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Google.png'),
    primaryButton: {
      linkType: 'calendly',
      link: PERCENT_SERVICE_CALENDLY_URL_TILES,
      linkText: 'productOffers.buyNow'
    },
    secondaryButton: {
      linkType: 'calendly',
      link: PERCENT_SERVICE_CALENDLY_URL_TILES,
      linkText: 'productOffers.bookCall'
    }
  },
  googleAdsSupport: {
    type: ProductOfferType.PercentService,
    name: 'Google Ad Grants support',
    tracking: ProgramName.GoogleAdGrant,
    description: 'Create, manage, and optimize your Ad Grants with digital marketing specialists',
    logo: require('@percent/cause-dashboard/common/assets/images/partner-offers/Google.png'),
    primaryButton: {
      linkType: 'calendly',
      link: PERCENT_SERVICE_CALENDLY_URL_TILES,
      linkText: 'productOffers.buyNow'
    },
    secondaryButton: {
      linkType: 'calendly',
      link: PERCENT_SERVICE_CALENDLY_URL_TILES,
      linkText: 'productOffers.bookCall'
    }
  },
  managedItSupport: {
    type: ProductOfferType.PercentService,
    name: 'Managed IT support',
    tracking: ProgramName.ManagedIT,
    description: 'Ongoing IT support for software planning and implementation',
    primaryButton: {
      linkType: 'calendly',
      link: PERCENT_SERVICE_CALENDLY_URL_TILES,
      linkText: 'productOffers.bookCall'
    },
    secondaryButton: undefined
  },
  socialMediaManagement: {
    type: ProductOfferType.PercentService,
    name: 'Social media management',
    tracking: ProgramName.SocialMedia,
    description: 'Ongoing IT support for software planning and implementation',
    primaryButton: {
      linkType: 'calendly',
      link: PERCENT_SERVICE_CALENDLY_URL_TILES,
      linkText: 'productOffers.bookCall'
    },
    secondaryButton: undefined
  },
  websiteCmsSupport: {
    type: ProductOfferType.PercentService,
    name: 'Website & CMS support',
    tracking: ProgramName.WebCMS,
    description: 'Engage donors with expertly designed and managed websites',
    primaryButton: {
      linkType: 'calendly',
      link: PERCENT_SERVICE_CALENDLY_URL_TILES,
      linkText: 'productOffers.bookCall'
    },
    secondaryButton: undefined
  }
} as const

type PercentServicesKeys = keyof typeof percentServicesCatalog

export const percentServices = percentServicesCatalog as Record<PercentServicesKeys, ProductOffer>
