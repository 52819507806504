import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import { useAuthState, useQueryList } from '@percent/cause-dashboard/common/hooks'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { MilestonesTable } from './milestonesTable/MilestonesTable'
import { AddMilestoneModal } from './modals/addMilestone/AddMilestoneModal'

const unSupportedLocation = ['GBR', 'USA', 'AUS']

export function MilestonesContainer() {
  const {
    authState: { user, organisation }
  } = useAuthState()
  const { milestonesService } = useServices()
  const [{ data, isLoading, errorMessage, totalResults }, { refresh, nextPage, previousPage }] = useQueryList(
    milestonesService.getMoneyMilestones(user?.organisationId as string),
    {}
  )
  const [isAddMilestoneModalOpen, setIsAddMilestoneModalOpen] = useState(false)

  const handleAddMilestoneModalOpen = () => {
    setIsAddMilestoneModalOpen(true)
  }

  const handleAddMilestoneModalClose = () => {
    setIsAddMilestoneModalOpen(false)
  }

  const noMilestoneInLocation = !unSupportedLocation.includes(organisation?.countryCode || '')

  return (
    <Grid container spacing={3} direction="column" wrap="nowrap">
      <MilestonesTable
        data={data}
        isLoading={isLoading}
        errorMessage={errorMessage}
        totalResults={totalResults}
        addItem={handleAddMilestoneModalOpen}
        refresh={refresh}
        nextPage={nextPage}
        previousPage={previousPage}
        noMilestoneInLocation={noMilestoneInLocation}
        currencyCode={organisation?.currencyCode}
      />
      <AddMilestoneModal open={isAddMilestoneModalOpen} onClose={handleAddMilestoneModalClose} refresh={refresh} />
    </Grid>
  )
}
