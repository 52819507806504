import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Toolbar,
  Typography
} from '@material-ui/core'
import React, { useState } from 'react'
import getSymbolFromCurrency from 'currency-symbol-map'

import { DeleteMilestoneModal } from '../modals/deleteMilestone/DeleteMilestoneModal'
import { EditMilestoneModal } from '../modals/editMilestone/EditMilestoneModal'
import { NoResultTable, PaginationActions, Loader, ErrorView } from '@percent/cause-dashboard/common/components'
import { Alert, Button, Menu, Spacer } from '@percent/lemonade'
import styles from './MilestonesTable.module.scss'
import { useTranslation } from 'react-i18next'
import { MilestonesTableProps } from './MilestonesTable.types'
import { MilestonesItem } from '@percent/cause-dashboard/services/milestones/milestonesService.types'
import { formatMoney } from '@percent/cause-dashboard/common/utility/utility'
import EmptyTableImage from '@percent/cause-dashboard/common/assets/images/no-shopping-items.svg'

export enum MilestoneModalState {
  FORM,
  ACKNOWLEDGE
}
export function MilestonesTable({
  data,
  isLoading,
  errorMessage,
  totalResults,
  addItem,
  refresh,
  nextPage,
  previousPage,
  noMilestoneInLocation,
  currencyCode
}: MilestonesTableProps) {
  const { t } = useTranslation()
  const [editMilestone, setEditMilestone] = useState<MilestonesItem | undefined>(undefined)
  const [isEditMilestoneModalOpen, setIsEditMilestoneModalOpen] = useState(false)
  const [isDeleteMilestoneModalOpen, setIsDeleteMilestoneModalOpen] = useState(false)

  const [shoppingListItemId, setShoppingListItemId] = useState({
    sId: '',
    reward: ''
  })

  const handleAddMilestone = () => {
    addItem()
  }

  const handleOpenEditMilestoneModal = (id?: string) => () => {
    const milestone = data?.find((item: MilestonesItem) => item.id === id)
    setEditMilestone(milestone)
    setIsEditMilestoneModalOpen(true)
  }

  const handleCloseEditMilestoneModal = () => {
    setIsEditMilestoneModalOpen(false)
    if (refresh) {
      refresh()
    }
  }

  const handleOpenDeleteMilestoneModal = () => {
    setIsDeleteMilestoneModalOpen(true)
  }

  const handleCloseDeleteMilestoneModal = () => {
    setIsDeleteMilestoneModalOpen(false)
  }

  const rowShoppingListItemClicked = (sId: string, reward: string) => {
    setShoppingListItemId({
      sId,
      reward
    })
  }

  if (isLoading) {
    return <Loader />
  }

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  return (
    <Grid item>
      <TableContainer component={Paper}>
        {!noMilestoneInLocation && (
          <Alert
            variant="info"
            title={`${t('typography.milestonesInfoOne')}${getSymbolFromCurrency(currencyCode || 'USD')}${t(
              'typography.milestonesInfoTwo'
            )}`}
          >
            {t('typography.milestonesInfoThree')}
          </Alert>
        )}
        {totalResults !== 0 && (
          <>
            <Spacer size={4} axis="horizontal" />
            <Toolbar className={styles.toolBar}>
              <Grid container justifyContent="space-between">
                <Typography variant="h6">{t('typography.allMilestones')}</Typography>
                {!noMilestoneInLocation && (
                  <Grid item>
                    <Button data-testid="container-add-item-button" onPress={handleAddMilestone}>
                      {t('button.addMilestone')}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Toolbar>
          </>
        )}
        {noMilestoneInLocation && (
          <NoResultTable image={EmptyTableImage} text={t('typography.noMilestonesInLocation')} />
        )}
        {totalResults === 0 && !noMilestoneInLocation && (
          <NoResultTable
            alertComponent={true}
            image={EmptyTableImage}
            text={t('typography.noMilestones')}
            actionsRow={
              <Button data-testid="add-shopping-list" type="submit" onPress={handleAddMilestone}>
                {t('button.addMilestone')}
              </Button>
            }
          />
        )}{' '}
        {(totalResults && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={styles.tableCellAmount}>{t('table.header.amount')}</TableCell>
                <TableCell>{t('table.header.canBeAchieved')}</TableCell>
                <TableCell className={styles.tableCellMenu}> {t('table.header.actions')} </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map(({ amount, reward, currencyCode, id }: MilestonesItem) => (
                <TableRow key={`donation-list-${id}`}>
                  <TableCell>{`${getSymbolFromCurrency(currencyCode)}${formatMoney(amount)}`}</TableCell>
                  <TableCell className={styles.reward}>{reward}</TableCell>
                  <TableCell className={styles.actionsCell} onClick={() => rowShoppingListItemClicked(id, reward)}>
                    <Menu
                      title={t('button.more')}
                      onSelect={key => {
                        if (key === 'edit') {
                          handleOpenEditMilestoneModal(id)()
                        }

                        if (key === 'delete') {
                          handleOpenDeleteMilestoneModal()
                        }
                      }}
                      sections={[
                        {
                          items: [
                            { key: 'edit', label: 'Edit' },
                            { key: 'delete', label: 'Delete' }
                          ]
                        }
                      ]}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell variant="footer" colSpan={5}>
                  <PaginationActions
                    count={totalResults}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    refresh={refresh}
                  />
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        )) ||
          ''}
      </TableContainer>
      <EditMilestoneModal
        milestoneId={editMilestone?.id || ''}
        milestoneAmount={editMilestone?.amount || 0}
        milestoneReward={editMilestone?.reward || ''}
        open={isEditMilestoneModalOpen}
        onClose={handleCloseEditMilestoneModal}
        refresh={refresh}
      />
      <DeleteMilestoneModal
        open={isDeleteMilestoneModalOpen}
        id={shoppingListItemId}
        onClose={handleCloseDeleteMilestoneModal}
        refresh={refresh}
      />
    </Grid>
  )
}
