import { CentralCardLayoutProps } from 'libs/domain/iam/src/components/CentralCardLayout/CentralCardLayout.types'
import { useSitewideMessage } from '@percent/cause-dashboard/common/hooks'
import { CentralCardLayout } from '@percent/domain/iam'
import { Footer, CausesPortalLogo } from '@percent/cause-dashboard/common/components'

export function CentralCardLayoutWrapper(props: Readonly<CentralCardLayoutProps>) {
  const { shouldShowSitewideMessage } = useSitewideMessage()

  return (
    <CentralCardLayout
      {...props}
      footer={<Footer />}
      isRebrandBanner={shouldShowSitewideMessage}
      logoHref={<CausesPortalLogo isCardLayout />}
    />
  )
}
