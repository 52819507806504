import React, { useEffect, useState } from 'react'
import { FormikProvider, useFormik } from 'formik'

import { validationSchema } from '../ModalHelpers'
import styles from '../ItemModal.module.scss'

import { EditMilestoneModalProps } from './EditMilestoneModal.types'
import { useAuthState, useMutation } from '@percent/cause-dashboard/common/hooks'
import { useTranslation } from 'react-i18next'
import {
  AcknowledgeModal,
  AcknowledgeModalProps,
  ConfirmationModal,
  FormField,
  Modal,
  TextArea
} from '@percent/lemonade'
import { FieldInputCurrency } from '@percent/cause-dashboard/common/components'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { PatchMilestonesItemProps } from '@percent/cause-dashboard/services/milestones/milestonesService.types'
import { floatToIntegerCurrency } from '@percent/cause-dashboard/common/utility/utility'
import { Typography } from '@material-ui/core'
import getSymbolFromCurrency from 'currency-symbol-map'
import { TypeOf } from 'yup'
import { MilestoneModalState } from '../../milestonesTable/MilestonesTable'

export function EditMilestoneModal({
  open,
  onClose,
  milestoneId,
  milestoneAmount,
  milestoneReward
}: Readonly<EditMilestoneModalProps>) {
  const {
    authState: { user, organisation }
  } = useAuthState()
  const { t } = useTranslation()
  const { milestonesService } = useServices()
  const [modalState, setModalState] = useState(MilestoneModalState.FORM)
  const [acknowledgeModalData, setAcknowledgeModalData] = useState<AcknowledgeModalProps>({
    title: '',
    description: '',
    result: 'positive'
  })
  const [{ isLoading }, { apiFunc: patchMoneyMilestonesItem }] = useMutation(
    milestonesService.patchMilestonesItem,
    () => {
      setAcknowledgeModalData({
        title: t('dialog.editMilestone.success'),
        result: 'positive'
      })
      setModalState(MilestoneModalState.ACKNOWLEDGE)
    },
    () => {
      setAcknowledgeModalData({
        title: t('dialog.editMilestone.failure'),
        result: 'negative'
      })
      setModalState(MilestoneModalState.ACKNOWLEDGE)
    }
  )

  const handleCloseModal = () => {
    resetForm()
    setModalState(MilestoneModalState.FORM)
    onClose()
  }

  const formik = useFormik({
    initialValues: {
      amount: (milestoneAmount / 100).toFixed(2),
      reward: milestoneReward
    },
    validationSchema,
    onSubmit: submitValues => {
      const { amount: inputAmount, reward } = submitValues as unknown as TypeOf<typeof validationSchema>

      const amount = floatToIntegerCurrency(inputAmount)
      patchMoneyMilestonesItem(buildShoppingListItem(user?.organisationId as string, amount, reward))
    }
  })

  const { errors, values, setValues, touched, handleChange, handleBlur, handleSubmit, resetForm, setFieldValue } =
    formik

  useEffect(() => {
    setValues({
      amount: (milestoneAmount / 100).toFixed(2),
      reward: milestoneReward
    })
  }, [milestoneAmount, milestoneReward, setValues])

  const buildShoppingListItem = (orgId: string, amount: number, reward: string): PatchMilestonesItemProps => {
    return {
      organisationId: orgId,
      id: milestoneId,
      amount,
      reward
    }
  }

  return (
    <Modal open={open} onClose={onClose}>
      {modalState === MilestoneModalState.FORM && (
        <ConfirmationModal
          title={t('dialog.editMilestone.title')}
          loading={isLoading}
          primaryButtonText={t('button.saveChanges')}
          primaryBtnTestId="edit-save-item-button"
          type="submit"
          secondaryButtonText={t('button.cancel')}
          secondaryBtnTestId="edit-cancel-button"
          handleSubmit={handleSubmit}
          handleClose={handleCloseModal}
        >
          <Typography className={styles.text}>
            {t('dialog.moneyMilestone.helperTextOne')}
            {getSymbolFromCurrency(organisation?.currencyCode || 'USD')}
            {t('dialog.moneyMilestone.helperTextTwo')}
          </Typography>
          <form onSubmit={handleSubmit}>
            <FormikProvider value={formik}>
              <FieldInputCurrency
                name="itemAmount"
                label={t('form.itemAmount')}
                placeHolder={t('form.itemAmountPlaceholder')}
                value={values.amount}
                onChange={(a: string) => setFieldValue('amount', a)}
                handleBlur={handleBlur}
                error={errors.amount}
                addOn={organisation?.currencyCode}
                touched={touched.amount}
                isRequiredField
              />
              <FormField
                label={t('form.milestoneReward')}
                status={touched.reward && errors.reward ? 'danger' : 'default'}
                statusMessage={errors.reward}
                data-testid="itemReward"
                necessity="required"
              >
                <TextArea
                  name="reward"
                  maxLength={255}
                  placeholder={t('form.itemRewardPlaceholder')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.reward}
                />
              </FormField>
            </FormikProvider>
          </form>
        </ConfirmationModal>
      )}
      {modalState === MilestoneModalState.ACKNOWLEDGE && (
        <AcknowledgeModal
          title={acknowledgeModalData.title}
          handleClose={handleCloseModal}
          result={acknowledgeModalData.result as 'positive' | 'negative'}
          buttonText={t('button.done')}
          buttonTestId="edit-milestone-acknowledge-modal-button"
          viewTestId="edit-milestone-acknowledge-modal-view"
        />
      )}
    </Modal>
  )
}
