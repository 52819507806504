import React from 'react'
import {
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
  Tabs,
  Tab,
  Toolbar,
  Grid
} from '@material-ui/core'

import { Loader, PaginationActions, NoResultTable, TableHeadSorter } from '@percent/cause-dashboard/common/components'
import { TableProps } from './Table.types'
import styles from './Table.module.scss'
import { kebabCase } from '@percent/cause-dashboard/common/utility/kebabCase/kebabCase'
import { Tooltip } from '@percent/lemonade'

export const Table = <DataType,>({
  isLoading,
  totalResults,
  previousPage,
  nextPage,
  value,
  columns,
  handleChange,
  order,
  orderBy,
  tabs,
  title,
  children,
  emptyTableText,
  emptyTableChildren,
  className,
  emptyTableImage,
  filtersContent,
  toolTipTabs
}: TableProps<DataType>) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <TableContainer component={Paper} className={`${className} ${styles.tableContainer}`}>
          {title ? <Toolbar className={styles.toolbar}>{title}</Toolbar> : null}
          {tabs && (
            <>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                className={styles.tabs}
              >
                {tabs.map((tab: string, index: number) => (
                  <Tab
                    key={tab}
                    label={
                      <Tooltip
                        content={(toolTipTabs && toolTipTabs[index]) || ''}
                        aria-label="add"
                        data-testid="popover"
                        placement="bottom-start"
                      >
                        <span>{tab}</span>
                      </Tooltip>
                    }
                    disableRipple
                    data-testid={kebabCase(tab)}
                  />
                ))}
              </Tabs>
              <div className={styles.indicator} />
            </>
          )}
          {filtersContent}
          <MuiTable className={styles.table}>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell align="center">
                    <Loader />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : totalResults === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell align="center">
                    <NoResultTable text={emptyTableText} image={emptyTableImage} actionsRow={emptyTableChildren} />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <>
                <TableHeadSorter order={order} orderBy={orderBy} cells={columns} />
                <TableBody>{children}</TableBody>
                <TableFooter>
                  <TableRow className={styles.footerRow}>
                    <TableCell className={styles.footerCell} colSpan={10}>
                      <PaginationActions
                        count={totalResults}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        refresh={null}
                      />
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </>
            )}
          </MuiTable>
        </TableContainer>
      </Grid>
    </Grid>
  )
}
