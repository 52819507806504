import { ReactComponent as ErrorResultBadge } from '@percent/cause-dashboard/common/assets/images/error-result-badge.svg'

import styles from '../AgentVerify.module.scss'

import { AgentVerifyErrorViewProps } from './AgentVerifyErrorView.types'
import { HELP_LINK_TEXT } from '../AgentVerify'
import { useTranslation } from 'react-i18next'
import { HELP_LINK } from '@percent/cause-dashboard/constants/support'
import { CentralCardLayoutWrapper } from '@percent/cause-dashboard/common/components'

export function AgentVerifyErrorView({ error }: Readonly<AgentVerifyErrorViewProps>) {
  const { t } = useTranslation()

  const isTokenInvalid = error?.code === 'agent_verification_organisation_association/invalid_token'

  return (
    <CentralCardLayoutWrapper>
      <div className={styles.contentWrapper}>
        <div className={styles.resultBadgeWrapper} data-testid="error-result-badge">
          <ErrorResultBadge />
        </div>
        <h3>{t('errorMessage.somethingWentWrong')}</h3>
        <p>
          {t(isTokenInvalid ? 'errorMessage.tokenInvalid' : 'typography.pleaseRefresh')}{' '}
          {isTokenInvalid && (
            <a href={HELP_LINK} target="_blank" rel="noreferrer">
              {HELP_LINK_TEXT}
            </a>
          )}
        </p>
      </div>
    </CentralCardLayoutWrapper>
  )
}
