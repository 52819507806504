import { AcknowledgeModal, Modal, AcknowledgeModalProps, ConfirmationModal } from '@percent/lemonade'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styles from './RemoveUserModal.module.scss'
import { useMutation } from '@percent/cause-dashboard/common/hooks'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { HELP_LINK } from '@percent/cause-dashboard/constants/support'

interface RemoveUserModalProps {
  accountId: string
  email: string
  open: boolean
  close: (refetch: boolean) => void
}

enum RemoveUserModalContent {
  CONFIRMATION,
  ACKNOWLEDGE
}

export const RemoveUserModal = ({ accountId, email, open, close }: RemoveUserModalProps) => {
  const { t } = useTranslation()
  const [modalState, setModalState] = useState(RemoveUserModalContent.CONFIRMATION)
  const [acknowledgeModalData, setAcknowledgeModalData] = useState<AcknowledgeModalProps>({
    title: '',
    description: '',
    result: 'positive'
  })
  const { iamService } = useServices()

  const [{ isLoading: isDeleting }, { apiFunc: removeMemberFromOrg }] = useMutation(
    iamService.removeMember,
    _success => {
      setAcknowledgeModalData({
        title: t('typography.userManagement.removeUserSuccessModal.title'),
        description: t('typography.userManagement.removeUserSuccessModal.description', { email: email }),
        result: 'positive'
      })
      setModalState(RemoveUserModalContent.ACKNOWLEDGE)
    },
    _error => {
      setAcknowledgeModalData({
        title: t('typography.userManagement.removeUserFailureModal.title'),
        description: (
          <Trans
            i18nKey="typography.userManagement.removeUserFailureModal.description"
            values={{ email: email }}
            components={[
              <b key="email" />,
              <a href={HELP_LINK} target="_blank" rel="noreferrer" className={styles.helpLink} key="helpLink">
                {' '}
                Help Center{' '}
              </a>
            ]}
          />
        ),
        result: 'negative'
      })
      setModalState(RemoveUserModalContent.ACKNOWLEDGE)
    }
  )

  const handleRemoveMember = () => {
    removeMemberFromOrg({ accountId })
  }

  return (
    <Modal open={open} onClose={() => close(false)} data-testid="remove-user-modal">
      {modalState === RemoveUserModalContent.CONFIRMATION && (
        <ConfirmationModal
          title={t('typography.userManagement.removeUserModal.title')}
          type="submit"
          loading={isDeleting}
          primaryButtonText={t('button.removeMember')}
          primaryBtnTestId="button-remove-member"
          secondaryButtonText={t('button.cancel')}
          secondaryBtnTestId="button-cancel"
          handleClose={() => close(false)}
          handleSubmit={handleRemoveMember}
        >
          <Trans
            i18nKey="typography.userManagement.removeUserModal.description"
            values={{ email: email }}
            components={[<b />]}
          />
        </ConfirmationModal>
      )}
      {modalState === RemoveUserModalContent.ACKNOWLEDGE && (
        <AcknowledgeModal
          title={acknowledgeModalData.title}
          description={acknowledgeModalData.description}
          handleClose={() => close(acknowledgeModalData.result === 'positive')}
          result={acknowledgeModalData.result}
          buttonText={t('button.close')}
          buttonTestId="close-acknowledge-modal-button"
          viewTestId="remove-member-acknowledge-modal-view"
        />
      )}
    </Modal>
  )
}
