import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { Button, Checkbox, Loader, Text } from '@percent/lemonade'
import styles from './CommPreferencesCard.module.scss'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { useToast } from 'libs/shared/ui-lemonade/src/components/toast'
import { useMutation, useQuery } from '@percent/cause-dashboard/common/hooks'

export default function CommPreferencesCard() {
  const { t } = useTranslation()
  const { accountService } = useServices()
  const [{ data: marketingConsentData, isLoading, error }] = useQuery(accountService.getMarketingConsents, {})
  const [emailConsent, setEmailConsent] = useState(false)
  const [phoneConsent, setPhoneConsent] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const toast = useToast()
  const [{ isLoading: isSaving }, { apiFunc: saveMarketingConsent }] = useMutation(
    accountService.setMarketingConsent,
    _success => {
      setIsButtonDisabled(true)
      toast.addToast(t('typography.marketingConsent.success'), 'success')
    },
    _error => {
      toast.addToast(t('typography.marketingConsent.error'), 'error')
    }
  )

  useEffect(() => {
    if (error || !marketingConsentData) return

    const consents = marketingConsentData.data.data
    const emailConsent = consents.find(c => c.channel === 'email')
    const phoneConsent = consents.find(c => c.channel === 'phone')

    setEmailConsent(!!emailConsent?.acceptedAt)
    setPhoneConsent(!!phoneConsent?.acceptedAt)
  }, [marketingConsentData, error])

  const saveCommsPreferences = () => {
    const oldEmailConsent = marketingConsentData?.data.data.find(c => c.channel === 'email')
    const oldPhoneConsent = marketingConsentData?.data.data.find(c => c.channel === 'phone')

    saveMarketingConsent({
      consents: [
        { channel: 'email', consent: emailConsent, id: oldEmailConsent?.id },
        { channel: 'phone', consent: phoneConsent, id: oldPhoneConsent?.id }
      ]
    })
  }

  const setEmailPreferences = (newChecked: boolean) => {
    setEmailConsent(newChecked)
    setIsButtonDisabled(false)
  }

  const setPhonePreferences = (newChecked: boolean) => {
    setPhoneConsent(newChecked)
    setIsButtonDisabled(false)
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <div className={styles.contentCtn}>
      <div className={styles.textWrapper}>
        <Text>{t('typography.marketingConsent.subtitle')}</Text>
      </div>
      <div className={styles.checkboxWrapper}>
        <Checkbox
          name="email"
          variant="default"
          onChange={target => {
            setEmailPreferences(target.currentTarget.checked)
          }}
          label={t('typography.marketingConsent.email')}
          active={emailConsent}
          dataTestId="email-checkbox"
        />
      </div>
      <div className={styles.checkboxWrapper}>
        <Checkbox
          name="phone"
          variant="default"
          onChange={target => {
            setPhonePreferences(target.currentTarget.checked)
          }}
          label={t('typography.marketingConsent.phone')}
          active={phoneConsent}
          dataTestId="phone-checkbox"
        />
      </div>
      <div className={styles.buttonCtn}>
        <Button
          variant="primary"
          onPress={() => saveCommsPreferences()}
          disabled={isButtonDisabled}
          loading={isSaving}
          data-testid="save-changes-button"
        >
          {t('button.saveChanges')}
        </Button>
      </div>
    </div>
  )
}
