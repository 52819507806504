import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { useLocation } from 'react-router-dom'
import { Spinner } from '@percent/lemonade'
import { NextAction } from '@percent/cause-dashboard/services/invite/inviteService.types'
import { Box } from '@mui/material'
import { SetPasswordToJoinOrganisation } from './SetPasswordToJoinOrganisation'
import { useEffect, useState } from 'react'
import { InvitationStatusScreen } from './InvitationStatusScreen'
import { useMutation, SignUpReason, useSignUpAttribution } from '@percent/cause-dashboard/common/hooks'

export function AcceptInvitePage() {
  const { search } = useLocation()
  const inviteId = new URLSearchParams(search).get('inviteId') || ''
  const { inviteService, accountService, authService } = useServices()
  const [hasInitialisedInviteRequest, setHasInitialisedInviteRequest] = useState<boolean>(false)
  const [isAccountCreated, setIsAccountCreated] = useState<boolean>(false)
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false)
  const { setSignUpAttributionManually } = useSignUpAttribution()

  const [
    { data: acceptInviteResponse, isLoading: isLoadingInvite, error: inviteError },
    { apiFunc: runAcceptInviteMutation }
  ] = useMutation(inviteService.acceptInvite)

  const [{ isLoading: isCreatingAccount, error: createAccountError }, { apiFunc: createAccount }] = useMutation(
    async ({ password, consent }: { password: string; consent: boolean }) => {
      const {
        data: { email }
      } = await inviteService.createAccount({ inviteId, password })
      await authService.login({ email, password })
      await accountService.setMarketingConsent({
        consents: [
          { channel: 'email', consent: consent },
          { channel: 'phone', consent: consent }
        ]
      })

      setIsAccountCreated(true)
    }
  )

  useEffect(() => {
    if (hasInitialisedInviteRequest) {
      return
    }

    setHasInitialisedInviteRequest(true)
    runAcceptInviteMutation({
      inviteId
    })
  }, [hasInitialisedInviteRequest, inviteId, runAcceptInviteMutation])

  useEffect(() => {
    setSignUpAttributionManually(SignUpReason.INVITED)
  }, [setSignUpAttributionManually])

  const organisationData = {
    name: acceptInviteResponse?.data.data?.organisationName ?? '',
    inviterEmail: acceptInviteResponse?.data.data?.inviterEmail ?? '',
    logo: acceptInviteResponse?.data.data?.logo,
    website: acceptInviteResponse?.data.data?.organisationWebsite
  }

  const getLayout = () => {
    if (acceptInviteResponse?.data.nextAction === NextAction.CREATE_ACCOUNT) {
      return (
        <SetPasswordToJoinOrganisation
          setPassword={createAccount}
          organisationData={organisationData}
          errorMessage={createAccountError?.message}
          isAccountActive={isAccountCreated}
          isFormSubmitted={isFormSubmitted}
          setIsFormSubmitted={setIsFormSubmitted}
        />
      )
    } else if (acceptInviteResponse?.data.nextAction === NextAction.SIGN_IN) {
      return <InvitationStatusScreen hasAccount />
    } else {
      return <InvitationStatusScreen errorCode={inviteError?.code} />
    }
  }

  if (isLoadingInvite || isCreatingAccount) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xxl" />
      </Box>
    )
  }

  return <>{getLayout()}</>
}
