import styles from './InvitationStatusScreen.module.scss'
import { useTranslation } from 'react-i18next'
import { Button, Alert, Spacer, Heading } from '@percent/lemonade'
import { useHistory } from 'react-router-dom'
import { SplitPagesContainer } from '../../layout/splitPagesContainer/SplitPagesContainer'
import { HELP_LINK } from '@percent/cause-dashboard/constants/support'
import { RoutePath } from '../../routes/Routes'

enum ErrorKey {
  InviteExpired = 'iam/invite_expired',
  InviteAlreadyAccepted = 'iam/invite_already_accepted',
  UserAlreadyJoinedOrganisation = 'iam/user_already_joined_organisation',
  UserAlreadyJoinedDifferentOrganisation = 'iam/user_already_joined_different_organisation'
}

interface InvitationStatusScreenProps {
  errorCode?: string
  hasAccount?: boolean
}

export function InvitationStatusScreen({ errorCode, hasAccount = false }: Readonly<InvitationStatusScreenProps>) {
  const { t } = useTranslation()
  const history = useHistory()

  const errorToAlertMap: Record<string, { variant: 'info' | 'error'; title: string; children: string | JSX.Element }> =
    {
      [ErrorKey.InviteExpired]: {
        variant: 'error',
        title: t('errorMessage.inviteExpired.title'),
        children: t('errorMessage.inviteExpired.children')
      },
      [ErrorKey.InviteAlreadyAccepted]: {
        variant: 'error',
        title: t('errorMessage.inviteAlreadyAccepted.title'),
        children: ''
      },
      [ErrorKey.UserAlreadyJoinedOrganisation]: {
        variant: 'error',
        title: t('errorMessage.userAlreadyJoinedOrg.title'),
        children: ''
      },
      [ErrorKey.UserAlreadyJoinedDifferentOrganisation]: {
        variant: 'error',
        title: t('errorMessage.userAlreadyJoinedDifferentOrg.title'),
        children: (
          <span>
            {t('errorMessage.userAlreadyJoinedDifferentOrg.children')}
            <a href={HELP_LINK}>{t('emailVerification.helpCenter')}</a>.
          </span>
        )
      },
      otherError: {
        variant: 'error',
        title: t('errorMessage.unknownError.title'),
        children: (
          <span>
            {t('errorMessage.unknownError.children')}
            <a href={HELP_LINK}>{t('emailVerification.helpCenter')}</a>.
          </span>
        )
      }
    }

  const getErrorFromMap = () => {
    if (!errorCode || !Object.keys(errorToAlertMap).includes(errorCode)) {
      return errorToAlertMap.otherError
    }
    return errorToAlertMap[errorCode]
  }

  const { children, variant, title } = getErrorFromMap()

  const handleNavigation = () => {
    history.push(RoutePath.SIGNIN)
  }

  const isAcceptInviteTitle =
    title === errorToAlertMap[ErrorKey.InviteAlreadyAccepted].title ||
    title === errorToAlertMap[ErrorKey.UserAlreadyJoinedOrganisation].title ||
    title === errorToAlertMap[ErrorKey.UserAlreadyJoinedDifferentOrganisation].title

  return (
    <SplitPagesContainer>
      <div className={styles.statusContainer} data-testid="invitation-status-screen">
        {errorCode ? (
          <>
            {isAcceptInviteTitle ? (
              <>
                <Heading level="h4">{t('invite.accept.title')}</Heading>
                <Spacer size={6} axis="vertical" />
                <div>{t('typography.acceptInvitation')}</div>
              </>
            ) : (
              <Heading level="h4">{t('errorMessage.somethingWentWrong')}</Heading>
            )}
            <Spacer size={6} axis="vertical" />
            <Alert variant={variant} title={title}>
              {' '}
              {children} <span data-testid={errorCode} />
            </Alert>
            <Spacer size={6} axis="vertical" />
            {isAcceptInviteTitle && (
              <Button size="large" stretch onPress={handleNavigation} data-testid="signin-button">
                {t('button.signIn')}
              </Button>
            )}
          </>
        ) : (
          hasAccount && (
            <>
              <Heading level="h4">{t('invite.accept.title')}</Heading>
              <Spacer size={6} axis="vertical" />
              <Alert variant="info" title={t('typography.account.alreadyExists')}></Alert>
              <Spacer size={6} axis="vertical" />
              <Button size="large" stretch onPress={handleNavigation} data-testid="signin-button">
                {t('button.signIn')}
              </Button>
            </>
          )
        )}
      </div>
    </SplitPagesContainer>
  )
}
