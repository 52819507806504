import { Footer } from '@percent/cause-dashboard/common/components'
import { ExpiredSessionHandle } from '@percent/domain/iam'
import { useTranslation } from 'react-i18next'
import { RoutePath } from '../../routes/Routes'

export function ExpiredSessionHandleContainer() {
  const { t } = useTranslation()

  const nextUrl = new URLSearchParams(window.location.search).get('next')
  const redirectRoute = nextUrl ? `${RoutePath.SIGNIN}?next=${nextUrl}` : RoutePath.SIGNIN

  return (
    <ExpiredSessionHandle
      heading={t('typography.sessionHasExpired')}
      description={t('typography.loggedOutFromThisSession')}
      buttonText={t('button.logIn')}
      redirectRoute={redirectRoute}
      footer={<Footer />}
    />
  )
}
