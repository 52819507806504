import {
  iamServiceCapabilities,
  getAccountsProps,
  putRoleProps,
  deleteAccountProps,
  inviteUserProps,
  deleteMemberProps
} from './iam.types'
import { IamGetAccounts, IamGetInvites, IamGetRoles } from '../types/iamResponses.types'
import { PBPListResponse, PBPResponse } from '@percent/cause-dashboard/services/types/pbpResponses.types'
import { apiPaths } from '../apiPaths'

export const iamService = ({ percentClient }: iamServiceCapabilities) => ({
  patchRoles: ({ accountId, roleId }: putRoleProps) =>
    percentClient.patch(`${apiPaths.v1IamAccounts}/${accountId}/role`, { roleId }),
  getRoles: () => percentClient.get<PBPResponse<IamGetRoles>>(apiPaths.v1IamRoles),
  getPermissions: () => percentClient.get(apiPaths.v1IamPermissions),
  putRolesAttach: (params: putRoleProps) => percentClient.put(apiPaths.v1IamAccountsRolesAttach, { params }),
  getAccounts: (params: getAccountsProps) =>
    percentClient.get<PBPListResponse<IamGetAccounts>>(apiPaths.v1IamAccounts, { params }),
  deleteAccount: (params: deleteAccountProps) =>
    percentClient.delete<PBPListResponse<IamGetAccounts>>(apiPaths.v1IamAccounts, { params }),
  getInvites: () => percentClient.get<PBPListResponse<IamGetInvites>>(apiPaths.v1IamAccountsInvite),
  inviteUser: (params: inviteUserProps) =>
    percentClient.post(`${apiPaths.v1IamAccountsInvite}/${params.organisationId}`, {
      email: params.email,
      roleId: params.roleId
    }),
  removeMember: ({ accountId }: deleteMemberProps) =>
    percentClient.delete(`${apiPaths.v1IamAccounts}/${accountId}/role`)
})
