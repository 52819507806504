import { useMutation } from '@percent/cause-dashboard/common/hooks'
import { ErrorView, CentralCardLayoutWrapper } from '@percent/cause-dashboard/common/components'
import { EmailVerificationDetail } from './EmailVerificationDetail'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { useToast } from '@percent/lemonade'

export function EmailVerificationPage() {
  const toast = useToast()
  const { authService } = useServices()
  const [{ isLoading, errorMessage, success }, { apiFunc: verifyAccountRequest }] = useMutation(
    authService.verifyAccountRequest
  )
  const handleEmailSubmit = () => {
    verifyAccountRequest(undefined)
    toast.addToast('Email resent', 'success')
  }

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  return (
    <CentralCardLayoutWrapper>
      <EmailVerificationDetail handleResendEmailSubmit={handleEmailSubmit} loading={isLoading} success={success} />
    </CentralCardLayoutWrapper>
  )
}
